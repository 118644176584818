import React, { useState } from 'react';
import LoginImg from '../../../Assets/img/Rectangle.png';
import Button from 'react-bootstrap/Button';
import Resendotp from './Resetotp';
import axios from 'axios';
import { toast } from "react-toastify";
function Forget({ handleBackdropClick , setHandleLoginShow }) {
    const [email, setEmail] = useState("");
    const [resetPassword, setResetPassword] = useState(true);
    const [fieldWarning, setFieldWarning] = useState(false);

    const handleResetPass = async () => {
        // Validate email before attempting to reset password
        if (!email.trim()) {
            // Show warning for empty email
            setFieldWarning(true);
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/forgot-password`, {
                email: email
            });
            toast.success(response?.data?.message || "OTP sent successfully to your email");
            setResetPassword(false);
        } catch (error) {
            //console.log(error, "error");
            toast.error(error?.response?.data?.message || "Something went wrong");
        }
    };

    return (
        <div>
            {resetPassword ? (
                <div className='flex flex-row items-start'>
                    <div className='w-[400px] h-[430px] hidden md:block rounded-2xl'>
                        <img src={LoginImg} alt="login_img" className='w-full h-full rounded-lg' />
                    </div>
                    <div className='w-[400px] px-4 py-3'>
                        <div className='my-3 mt-10 text-center'>
                            <h5 className=''>Forgot Password</h5>
                        </div>

                        <div className='mb-3'>
                            <p className='leading-6 text-slate-6000 font-[600] mb-0'>Email</p>
                            <input
                                type="text"
                                placeholder='Enter the Email'
                                className={`w-full outline-none bg-[#F2F5F8] shadow-sm px-1 py-2 rounded-lg ${
                                    fieldWarning ? "border-red-500" : ""
                                }`}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {fieldWarning && (
                                <div className="text-red-500 text-sm mt-1">Email is required</div>
                            )}
                        </div>
                        <div className='w-full my-4'>
                            <Button
                                style={{ padding: "10px 18px", textAlign: 'center', border: "none", borderRadius: '40px' }}
                                className='w-full hover:opacity-80 btn btn-danger'
                                onClick={handleResetPass}
                            >
                               Send OTP
                            </Button>
                        </div>
                   
                    </div>
                </div>
            ) : (
                <Resendotp email={email} handleBackdropClick={handleBackdropClick} setHandleLoginShow  ={setHandleLoginShow} />
            )}
        </div>
    );
}

export default Forget;
