import React, { useEffect, useRef, useState } from "react";
import HotelCard from "./HotelCard";
import { useAppContext } from "../../context/store";

function FilterCard({ filteredHotels, isWeekend, holidays, weekendHolidays }) {
  const { state } = useAppContext();
  const hotelData = state.hotel;

  const initialCardsCount = 8;
  const additionalCardsCount = 5; 
  const [visibleCardsCount, setVisibleCardsCount] = useState(initialCardsCount);
  const [loading, setLoading] = useState(true);
  const [sortedHotels, setSortedHotels] = useState([]);

  // Function to sort hotels based on promoted status
  const sortHotels = (hotels) => {
    const promotedHotels = hotels.filter((hotel) => hotel.promoted?.status);
  
    // Fisher-Yates shuffle algorithm
    const shuffledPromotedHotels = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };
  
    const shuffledPromotedHotelsArray = shuffledPromotedHotels([...promotedHotels]);
    const nonPromotedHotels = hotels.filter((hotel) => !hotel.promoted?.status);
  
    const sortedArray = [...shuffledPromotedHotelsArray, ...nonPromotedHotels];
    setSortedHotels(sortedArray); // Set sortedHotels state
    return sortedArray;
  };

  useEffect(() => {
    sortHotels(filteredHotels); 
    setLoading(false); 
  }, [filteredHotels]);

  const handleShowMore = () => {
    setVisibleCardsCount((prevCount) => prevCount + additionalCardsCount);
  };

  return (
    <div className="p-2">
      {loading ? (
        <div className="w-full text-center">
          <p className="text-center text-[30px] text-[#FF0000]">Loading...</p>
        </div>
      ) : (
        <>
          {sortedHotels && sortedHotels.length > 0 ? (
            sortedHotels.slice(0, visibleCardsCount).map((hotel) => (
              <HotelCard weekendHolidays={weekendHolidays} holidays={holidays} isWeekend={isWeekend} hotel={hotel} key={hotel._id} />
            ))
          ) : (
            <div className="w-full text-center">
              <p className="text-center text-[30px] text-[#FF0000]">No hotels available</p>
            </div>
          )}

          {visibleCardsCount < sortedHotels.length && (
            <div className="text-center mt-4">
              <button
                onClick={handleShowMore}
                className="text-[20px] text-[#FF0000] cursor-pointer"
              >
                Show more
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default FilterCard;
