import React from 'react'
import { FaStar } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Icon } from '@iconify/react';

import { useAppContext } from "../../context/store";

function HotelDiscription() {
  const mobileAligment = {
    textAlign: 'center',
  };

  const starArray = Array.from({ length: 5 }, (_, index) => index + 1); // Generate an array from 1 to 5 for star ratings

  const { state } = useAppContext();
  const hotelDetial = state.hotelDetails;

  // Use optional chaining to handle cases where hotelDetails may be undefined
  const hotelData = {
    location: hotelDetial?.country || 'Unknown Location',
    starRating: hotelDetial?.starRating || 0, // Replace with actual star rating from hotelDetails
    privateParking: hotelDetial?.parking?.isParkingAvailable === 'yesPaid' ? 'Available (Paid)' : 'Not Available',
    freeWifi: hotelDetial?.facilities?.accommodation?.includes('Free Wifi') ? 'Available' : 'Not Available',
    airportShuttle: hotelDetial?.transportation?.providePickUpService === 'yes' ? 'Available' : 'Not Available',
    familyRoom: hotelDetial?.hotelRules?.allowChildren === 'yes' ? 'Available' : 'Not Available',
    nonSmokingRoom: hotelDetial?.facilities?.accommodation?.includes('Non Smoking room') ? 'Available' : 'Not Available',
    restaurant: hotelDetial?.facilities?.accommodation?.includes('Restaurant') ? 'Available' : 'Not Available',
    twentyFourHourFrontDesk: hotelDetial?.facilities?.accommodation?.includes('24-hour front desk') ? 'Available' : 'Not Available',
    bar: hotelDetial?.facilities?.accommodation?.includes('Bar') ? 'Available' : 'Not Available',
    elevator: hotelDetial?.facilities?.accommodation?.includes('Elevator') ? 'Available' : 'Not Available',
    freeBreakfast: hotelDetial?.facilities?.accommodation?.includes('Free Breakfast') ? 'Available' : 'Not Available',
    nearbyLocations: hotelDetial?.nearbyLocations || ['Location 1', 'Location 2', 'Location 3'], // Replace with actual data from hotelDetails
    description: hotelDetial?.description || 'Description not available Description not available Description not available Description not available Description not availableDescription not availableDescription not availableDescription not availableDescription not availableDescription not availableDescription not availableDescription not availableDescription not availableDescription not availablevDescription not available', // Default description if not available
  };


  return (
    <div className='p-3'>
      <div className='w-full h-auto bg-white my-3 rounded-lg'>
        <div className='p-4'>
          <h3 className='text-[25px] md:text-[30px] font-[700] mb-0'>Description</h3>
          <div>
            <span>
              <FaMapMarkerAlt className='inline mr-1' />
            </span>
            <span className='text-[18px] text-[#292929]'>{hotelData.location}</span>

            {starArray.map((item, index) => (
              <FaStar key={index} className='inline ml-2 text-[#FFD250] font-[900]' />
            ))}
          </div>

          <div dangerouslySetInnerHTML={{ __html: hotelData?.description }} className='py-2 text-sm'>
          </div>
        </div>

        {/* Discription Icons */}
        <div className='px-4 pb-4'>
          <div style={mobileAligment} className='grid grid-cols-1 md:grid-cols-4 xl:grid-cols-5 gap-2 md:gap-4'>
            {state.hotelDetails.facilities.length > 0 && state.hotelDetails.facilities.map(x => {
              return (
                <div className='flex flex-row items-center justify-start mb-0'>
                  <Icon icon={x.icon} className='font-[800] text-xl text-[#129035]' />
                  <span className='ml-2 text-[16px] font-[500] tracking-wider text-sm'>{x.name}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HotelDiscription;
