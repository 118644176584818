import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAppContext } from "../../context/store";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import config from "../../config";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "../Fotter/Footer";
function Booktrips() {
  const [bookings, setBookings] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const { state } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [bookingId, setBookingId] = useState("");
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [totalDuration, setTotalDuration] = useState(null);

  function formatDate(date) {
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const formattedDate = `${weekdays[date.getDay()]} ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())} GMT${getTimezoneOffsetString(date.getTimezoneOffset())} (India Standard Time)`;
    return formattedDate;
}

function padZero(number) {
    return number < 10 ? `0${number}` : `${number}`;
}

function getTimezoneOffsetString(offset) {
    const sign = offset < 0 ? '+' : '-';
    const hours = padZero(Math.floor(Math.abs(offset) / 60));
    const minutes = padZero(Math.abs(offset) % 60);
    return `${sign}${hours}${minutes}`;
}
  const formatDateRange = (startDate, endDate) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedStartDate = new Date(startDate).toLocaleDateString(
      "en-US",
      options
    );
    const formattedEndDate = new Date(endDate).toLocaleDateString(
      "en-US",
      options
    );
    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const getBookings = async () => {
    try {
      const header = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/reservation/${state.profileData?._id}`,
        header
      );
      setBookings(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching bookings:", error.response);
      setLoading(false);
    }
  };

  useEffect(() => {
    getBookings();
  }, [state.profileData?._id]);

  const handleSubmit = async () => {
    handleClose();
    // console.log(cancellationReason  , "cancellationReason")
    if (!cancellationReason) {
      toast.warning("Cancellation reason is required.");
      return;
  }
  
  

    try {
      const token = localStorage.getItem("token");
      // console.log(token , "token")

      const headers = {
        Authorization: token ? `Bearer ${token}` : undefined,
        mysecret: config.MY_SECRET,
      };

      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/reservation/cancel/${bookingId}`,
        {
          headers,
        },
        cancellationReason
      );

      if (response.data.status) {
        toast.success(response.data.message);
        getBookings();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitDate = async () => {
    handleClose1();
    // console.log(checkInDate, "checkInDate");
    const newCheckInDate = new Date(checkInDate);
    const newCheckOutDate = new Date(newCheckInDate.getTime() + totalDuration * 24 * 60 * 60 * 1000);

    // Format new checkOut date to match the desired format
    const formattedCheckOutDate = formatDate(newCheckOutDate);
    // console.log(formattedCheckOutDate, "checkOut");


    try {
        const token = localStorage.getItem("token");

        const headers = {
            Authorization: token ? `Bearer ${token}` : undefined,
            mysecret: config.MY_SECRET,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/reservation/${bookingId}`,
          {
            checkIn: checkInDate,
            checkOut: formattedCheckOutDate,
          },
          {
            headers: headers,
          }
        );

        if (response.data.success) {
          toast.success(response.data.message);
          getBookings();
        }
    } catch (err) {
        toast.error(err.response.data.message);
        // console.log(err.response.data.message, err.response.status);
    }
};

  const handleCancelClick = (id) => {
    setBookingId(id);
    handleShow();
  };

  function calculateDaysGap(checkIn, checkOut) {
    // Parse the dates
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);

    // Calculate the time difference in milliseconds
    const timeDifference = checkOutDate.getTime() - checkInDate.getTime();

    // Convert time difference to days
    const daysGap = Math.ceil(timeDifference / (1000 * 3600 * 24));

    return daysGap;
  }

  const handleRescheduleClick = (id, checkOut, checkIn) => {
    const gap = calculateDaysGap(checkIn, checkOut);
    // console.log(checkIn , "checkIn");
    // console.log(checkOut , "checkOut");
    // console.log(gap , "gapdate");
    setTotalDuration(gap);

    setBookingId(id);
    handleShow1();
  };

  const isPastCancellationDeadline = (deadline) => {
    const currentDate = new Date();
    // console.log("currentDate", currentDate, "deadline ", new Date(deadline));
    return currentDate > new Date(deadline);
  };

  return (
    <div>
      <div className="w-full xl:w-[1000px] h-auto mx-auto p-2 xl:p-0 mt-0 mb-5">
        <h3
          className={`my-5 font-bold text-slate-700 text-[30px] md:text-[35px] ${
            window.innerWidth <= 768 ? "text-center" : "text-left"
          }`}
        >
          Booking & Trips
        </h3>
        {loading ? (
          <div className="w-full text-center p-10 h-screen">
            <ClipLoader color="#FF0000" size={60} />
          </div>
        ) : (
          <div>
            {bookings.length > 0 ? (
              <div className="p-2">
                {bookings.map((booking, index) => (
                  <div
                    key={index}
                    className="bg-[#fff] relative flex flex-col md:flex-row justify-between items-center p-3 my-2 rounded-md shadow-md"
                  >
                    <div className="w-full md:w-[200px] h-[180px]  mx-auto rounded-lg">
                      {/* Render hotel image (you can replace 'hotelImage' with the actual field name) */}
                      <img
                        src={
                          booking.hotel.propertyPicture[0]?.link ||
                          "fallbackImageURL"
                        }
                        alt="booking_images"
                        className="w-full h-full rounded-lg"
                      />
                    </div>
                    <div
                      className={`w-full md:w-[500px] h-auto md:h-[200px] ${
                        window.innerWidth <= 768
                          ? "border-none"
                          : "border-r-[1px]"
                      } border-slate-400  px-3`}
                    >
                      <h3 className="text-[28px] font-[600] leading-10 tracking-wider mt-3 line-clamp-1">
                        {booking.hotel.propertyName}
                      </h3>

                      <div className="flex items-center justify-between">
                        <p className="text-[18px] font-[400] leading-8 tracking-wider text-slate-400">
                          {formatDateRange(booking.checkIn, booking.checkOut)}
                        </p>
                      </div>
                      {/* <button
                        className="bg-[#e3292d] ml-[10px] px-[10px] py-[10px] rounded-md text-sm text-[#fff] font-[500] hover:bg-opacity-75"
                        onClick={() => handleRescheduleClick(booking._id)}
                      >
                        Reschedule Booking
                      </button><br/>
                      <button
                      className="bg-[#e3292d] px-[10px] py-[10px] rounded-md text-sm text-[#fff] font-[500] hover:bg-opacity-75"
                      onClick={() => handleCancelClick(booking._id)}
                    >
                      Cancel Booking
                    </button> */}

                      <span>
                        <p className="text-[16px] font-[500] left-8 tracking-wide text-[#fa9294]">
                          {booking.hotel.country}
                        </p>
                      </span>
                      <span>
                        <p
                          className="text-[16px] font-[500] left-8 tracking-wide"
                          style={{
                            color: `${
                              booking?.status === "confirmed"
                                ? "#4ae74a"
                                : "#fa9294"
                            }`,
                          }}
                        >
                          {booking?.status}
                        </p>
                      </span>
                    </div>
                    <div className="w-full md:w-[250px] h-auto md:h-[200px] mx-auto">
                      <h3 className="text-[20px] md:text-[30px] font-[700] leading-6 tracking-wider mt-3 text-left md:text-right">
                        {state.currency}
                      </h3>
                      <h3 className="text-[20px] md:text-[30px] font-[700] leading-6 tracking-wider mt-3 text-left md:text-right">
                        {(booking.price * state.rate).toFixed(2)}
                      </h3>
                    </div>
                    <div className="absolute right-4 sm:right-[300px] bottom-2">
                      {booking?.status !== "cancelled" &&
                        !isPastCancellationDeadline(
                          booking.rescheduledDealine
                        ) &&
                        !booking?.bookingUpdate?.rescheduled && (
                          <>
                            {" "}
                            <button
                              className="bg-[#e3292d] my-2 w-[150px] p-2 rounded-md text-[12px] text-[#fff] font-[500] hover:bg-opacity-75"
                              onClick={() =>
                                handleRescheduleClick(
                                  booking._id,
                                  booking.checkOut,
                                  booking.checkIn
                                )
                              }
                            >
                              Reschedule Booking
                            </button>
                            <br />
                          </>
                        )}

                      {booking?.refundable === true &&
                        booking?.status !== "cancelled" &&
                        !isPastCancellationDeadline(
                          booking.cancellationDeadline
                        ) &&
                        !booking?.bookingUpdate?.canceled && (
                          <button
                            className="bg-[#e3292d] w-[150px] p-2 rounded-md text-[12px] text-[#fff] font-[500] hover:bg-opacity-75"
                            onClick={() => handleCancelClick(booking._id)}
                          >
                            Cancel Booking
                          </button>
                        )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center w-full h-screen">
                <p className="text-red-600 text-2xl">No Bookings Available</p>
              </div>
            )}
          </div>
        )}

        {/* Modal  code */}
        <>
          <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Body>
              <div>
                <p className="font-[500]">Reason for Cancellation</p>
                <textarea
                  className="border-[1px] border-slate-400 w-full h-[150px] outline-none p-1"
                  value={cancellationReason}
                  onChange={(e) => setCancellationReason(e.target.value)}
                ></textarea>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="danger" onClick={handleSubmit}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </>

        <Modal show={show1} onHide={handleClose1} animation={false}>
          <Modal.Body>
            <div>
              <p className="font-[500] mb-[20px]">
                Select Check-In and Check-Out Dates
              </p>

              {/* Check-In Date */}
              <div className="mb-[20px]">
                <label
                  htmlFor="checkInDatePicker"
                  className="block text-sm font-medium text-slate-600"
                >
                  Check-In Date
                </label>
                <DatePicker
                  id="checkInDatePicker"
                  selected={checkInDate}
                  onChange={(date) => setCheckInDate(date)}
                  selectsStart
                  startDate={checkInDate}
                  endDate={checkOutDate}
                  placeholderText="Select Check-In Date"
                />
              </div>

              {/* Check-Out Date */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="danger" onClick={handleSubmitDate}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* Here is Our fotter */}
      <Footer />
    </div>
  );
}

export default Booktrips;
