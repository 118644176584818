import React, { useEffect, useState } from "react";
import Navbar from "../Home/Navbar";
import Searchbar from "../Home/Searchbar";
import Footer from "../Fotter/Footer";
import FilterSection from "./FilterSection";
import FixedFilter from "./FixedFilter";
import { FaFilter } from "react-icons/fa";
import lbDate from "lbdate";
import axios from "axios";
import { useAppContext } from "../../context/store";
import config from "../../config";

function Filter() {
  const [scrollLock, setScrollLock] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const lat = searchParams.get("latitude");
  const lng = searchParams.get("longitude");
  const location = searchParams.get("location");
  const checkIn = searchParams.get("checkIn");
  const children = searchParams.get("children");
  const checkOut = searchParams.get("checkOut");
  const room = searchParams.get("room");
  // console.log(room);
  const adult = searchParams.get("adult");
  const { state, actions } = useAppContext();
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isWeekend, setIsWeekend] = useState(false);

  const [minBudget, setMinBudget] = useState("");
  const [maxBudget, setMaxBudget] = useState("");

  const allHotels = state.hotel;
  const [filteredHotels, setFilteredHotels] = useState(allHotels);
  const [holidays, setHolidays] = useState(0);
  const [weekendHolidays, setWeekendHolidays] = useState(0);

  useEffect(() => {
    setLatitude(lat);
    setLongitude(lng);
  }, [lat, lng])

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/holidays`,
      {
        startDate: checkIn,
        endDate: checkOut
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          mysecret: config.MY_SECRET,
        },
      }).then(res => {
        // console.log(res.data.holidays);
        // Step 1: Get unique dates
        const uniqueDates = new Set();

        res.data.holidays.forEach((currentObject) => {
          const currentDate = new Date(currentObject.date);
          const dateWithoutTime = currentDate.toISOString().split('T')[0];
          uniqueDates.add(dateWithoutTime);
        });

        // Step 2: Classify into weekend and non-weekend dates
        const weekendDates = [];
        const nonWeekendDates = [];

        uniqueDates.forEach((date) => {
          const currentDate = new Date(date);
          const dayOfWeek = currentDate.getDay(); // Sunday is 0, Saturday is 6

          if (dayOfWeek === 0 || dayOfWeek === 6) {
            weekendDates.push(date);
          } else {
            nonWeekendDates.push(date);
          }
        });

        setWeekendHolidays(weekendDates.length)
        setHolidays(nonWeekendDates.length);
      }).catch(err => {
        console.log(err);
      })
  }, [checkIn, checkOut])

  const [filters, setFilters] = useState({
    propertyType: [],
    facilities: [],
    ammenities: [],
    starRating: [],
  });

  const applyFilters = () => {
    //console.log(filters, 'lllllllll');
    let updatedHotels = [...allHotels];

    if (filters.starRating.length > 0) {
      updatedHotels = updatedHotels.filter((hotel) =>
        filters.starRating.includes(hotel.rating.toString())
      );
    }

    if (filters.propertyType.length > 0) {
      updatedHotels = updatedHotels.filter((hotel) =>
        filters.propertyType.includes(hotel.propertyType)
      );
    }

    if (filters.facilities.length > 0) {
      updatedHotels = updatedHotels.filter(
        (hotel) =>
          hotel.facilities &&
          filters.facilities.every((facility) =>
            hotel.facilities.some(
              (f) =>
                (f.accommodation && f.accommodation.includes(facility)) ||
                (f.recreation && f.recreation.includes(facility))
            )
          )
      );
    }

    if (minBudget !== "" && maxBudget !== "") {
      updatedHotels = updatedHotels.filter(
        (hotel) =>
          hotel.rooms &&
          hotel.rooms[0] &&
          hotel.rooms[0].weekdayPrice &&
          parseInt(hotel.rooms[0].weekdayPrice) >= parseInt(minBudget) &&
          parseInt(hotel.rooms[0].weekdayPrice) <= parseInt(maxBudget)
      );
    } else if (minBudget !== "") {
      //console.log("minBudget", minBudget);
      //console.log("maxBudget", maxBudget);
      updatedHotels = updatedHotels.filter(
        (hotel) =>
          hotel.rooms &&
          hotel.rooms[0] &&
          hotel.rooms[0].weekdayPrice &&
          parseInt(hotel.rooms[0].weekdayPrice) >= parseInt(minBudget)
      );
    }

    //console.log(updatedHotels,'uuuuuuuu');

    setFilteredHotels(updatedHotels);
  };

  const getData = async () => {
    lbDate.init();

    try {
      actions.isLoading(true);

      let params = {
        location: encodeURIComponent(location),
        checkIn: checkIn,
        checkOut: checkOut,
        children: children,
        room: room,
        adult: adult,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/hotel/filter`,
        params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            mysecret: config.MY_SECRET,
          },
        }
      );

      actions.setHotel(response.data.data);
      //console.log(state.hotel, "state");
      actions.isLoading(false);
    } catch (error) {
      //console.log(error, "errrr");

      actions.setHotel([]);
      console.error("Error fetching hotels:", error);
    }
  };

  useEffect(() => {
    getData();

  }, [location, checkIn, checkOut, room, children, adult]);

  function hasWeekend() {
    let currentDate = new Date(checkIn);
    const endDateObj = new Date(checkOut);

    while (currentDate <= endDateObj) {
      const dayOfWeek = currentDate.getDay();

      // Check if the day is Saturday (6) or Sunday (0)
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        return true; // Weekend found
      }

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return false; // No weekend found
  }

  useEffect(() => {
    setIsWeekend(hasWeekend());
  }, []);

  // Function to toggle scroll lock
  const toggleScrollLock = () => {
    setScrollLock((prevScrollLock) => !prevScrollLock);
    document.body.style.overflow = scrollLock ? "auto" : "hidden";
  };
  const [showFilter, setShowFilter] = useState(false);
  const openMobileFliter = () => {
    setShowFilter(true);
    toggleScrollLock();
  };
  return (
    <div className="relative h-screen">
      <div>
        <div>
          <Navbar />
        </div>
        <div className="my-5 p-4 md:p-0">
          <Searchbar
            checkInD={checkIn}
            checkOutD={checkOut}
            adultD={adult}
            childD={children}
            roomD={room}
            locationD={location}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            latitude={latitude}
            longitude={longitude}
          />
        </div>
        <div className="block xl:hidden">
          {/* MobileFilter */}
          <div className="w-[170px] mx-auto mb-1">
            <button
              className="bg-[#f62c31] rounded-3xl w-full px-2 py-2"
              onClick={openMobileFliter}
            >
              <span className="text-[18px] font-[400] text-[#fff]">
                Apply Filter
              </span>
              <FaFilter className="inline font-[400] mx-1 text-[#fff]" />
            </button>
          </div>
        </div>
        <div className="w-full mb-4 sticky-filter">
          <FilterSection
            isWeekend={isWeekend}
            hasWeekend={hasWeekend}
            latitude={lat}
            longitude={lng}
            filteredHotels={filteredHotels}
            setFilteredHotels={setFilteredHotels}
            holidays={holidays}
            weekendHolidays={weekendHolidays}
          />
        </div>

        <div>
          <Footer />
        </div>
      </div>
      <FixedFilter
        applyFilters={applyFilters}
        filters={filters}
        setFilters={setFilters}
        maxBudget={maxBudget}
        setMaxBudget={setMaxBudget}
        minBudget={minBudget}
        setMinBudget={setMinBudget}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        scrollLock={scrollLock}
        allHotels={allHotels}
        filteredHotels={filteredHotels}
        setFilteredHotels={setFilteredHotels}
        setScrollLock={setScrollLock}
      />
    </div>
  );
}

export default Filter;
