const clientId = "1068146671501-j2nv50qepq0gf8ks5mre0hgbj88osnok.apps.googleusercontent.com"
const clientSecret = "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"
const FACEBOOK_APP_ID = "395092993028263"
const FACEBOOK_APP_SECRET = "d6733d998f0e969dbbfb4065994a2a89"
const MY_SECRET = "MY_SECRET"

module.exports = {
    clientId: clientId,
    clientSecret: clientSecret,
    FACEBOOK_APP_ID: FACEBOOK_APP_ID,
    FACEBOOK_APP_SECRET: FACEBOOK_APP_SECRET,
    MY_SECRET: MY_SECRET

};
