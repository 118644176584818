import React from 'react';
import { FaStar } from 'react-icons/fa';
import { useAppContext } from '../../context/store';

function HotelHaeding() {
  const starArray = [1, 2, 3, 4, 5];
  const { state } = useAppContext();
  const hotelDetial = state.hotelDetails;


  const hotelName = hotelDetial?.propertyName || 'Barcelo Anfa'; 
  const country = hotelDetial?.country + " " +  hotelDetial?.city + " " + hotelDetial?.address  || 'Nepal'; 
  const rating = hotelDetial?.rating || 0; 

  return (
    <div>
      <div className='px-4 md:px-0'>
        <div className='flex flex-col md:flex-row justify-between items-start md:items-center mb-0'>
          <h3 className='mt-2 mb-1 text-[28px] font-[700]'>{hotelName}</h3>
          <div className='mx-0 md:mr-3 text-[#FFDF00]'>
            {Array.from({length : Number(rating)}).map((item, index) => (
              <FaStar key={index} className={`inline mr-1 text-${index < rating ? 'yellow' : 'gray-300'} font-[900]`} />
            ))}
          </div>
        </div>
        <p className='text-[14px] md:text-[16px] font-[400] text-slate-800 my-2'>{country}</p>
      </div>
    </div>
  );
}

export default HotelHaeding;
