import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useAppContext } from "../../context/store";
import ViewImg from "../../Assets/img/simple.png";
import { useNavigate, Link } from "react-router-dom";
import BeatLoader from 'react-spinners/BeatLoader'
function Section() {
  
  const { state } = useAppContext();
  const url = state.link
  const mylink =  url.slice(8);

  const [isLoading, setIsLoading] = useState(true);
  const btnStyle = {
    padding: '10px 18px',
    textAlign: 'center',
    border: 'none',
    borderRadius: '40px',
  };
  
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(!state.hotel.length); // Set loading to true if hotel data is not available
  }, [state.hotel]);


  function formatDate(date) {
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }
  
  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  
  function constructHotelFilterURL(hotelId) {
    const today = new Date();
    const defaultCheckIn = formatDate(today);
    const defaultCheckOut = formatDate(addDays(today, 1));
    const defaultChildren = 0;
    const defaultRoom = 1;
    const defaultAdult = 2;
  
    const filterLocation = encodeURIComponent("New Delhi");
    const filterCheckIn = encodeURIComponent(defaultCheckIn);
    const filterCheckOut = encodeURIComponent(defaultCheckOut);
    const filterChildren = defaultChildren;
    const filterRoom = defaultRoom;
    const filterAdult = defaultAdult;
  
    const url = `/hoteldetails/${hotelId}/room?location=${filterLocation}&checkIn=${filterCheckIn}&checkOut=${filterCheckOut}&children=${filterChildren}&room=${filterRoom}&adult=${filterAdult}&latitude=28.6139391&longitude=77.2090212`;
  
    return url;
  }
  
  return (
    <div className="px-3 py-2">
      <div className="w-full xl:w-[1050px] bg-[#fff] shadow-sm border-[1px] border-slate-100 mx-auto p-3 mt-5 rounded-3xl">
        <section className="flex flex-col md:flex-row  items-center p-2">
          <div className="w-full md:w-[300px] bg-[#fff]   rounded-md">
            <p className="leading-3 bg-slate-700 w-[120px] py-2 px-2 uppercase text-sm
             rounded-md text-slate-200">
              Introducing
            </p>
            <h2 className="text-[35px] text-[#F62C31] font-[700] tracking-wider uppercase leading-10 ">
              Suva Trip <br /> <span className="text-[40px]">Special</span>
            </h2>
            <p className="leading-5 text-slate-500 w-[250px]">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur
              consectetur mollitia{" "}
            </p>
            <Button style={btnStyle} className="btn btn-danger"
              onClick={() => { navigate("/about") }}
            >Know more</Button>
          </div>
          <div className="flex flex-row items-start justify-between  gap-1 w-full md:w-[750px] mx-auto scroller overflow-x-auto p-0 md:p-2">
            {isLoading ? (
              <div className="w-full text-center">
                <BeatLoader color="#FF0000" size={20} />
              </div>
            ) : (
              state.hotel.slice(0, 3).map((data, index) => {
                return (
                  <Link
                  to={`/hoteldetails/${data._id}/room?${mylink}`}
                  key={data?._id}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <div
                    key={index}
                    className="w-[220px] lg:w-[200px] h-auto mr-[10px]  md:mx-[14px] my-4 md:my-0 shadow-md  rounded-xl bg-white"
                  >
                    <div className="w-full  h-[130px] rounded-xl">
                      <img
                        src={data.propertyPicture[0]?.link || ViewImg}
                        alt="viewImages"
                        className="w-full h-full rounded-xl"
                      />
                    </div>
                    <div className="w-full px-2 pt-2 pb-[2px]">
                      <h3 className="leading-5  h-[40px] text-sm md:text-[16px] font-[500] line-clamp-2">
                        {data.propertyName}
                        {/* {data.propertyName.length > 20
                          ? data.propertyName.substring(0, 20) + "..."
                          : data.propertyName || "Special Header"} */}
                      </h3>
                      <p className="text-sm leading-5 line-clamp-2 text-slate-500">
                        {data.description
                          ? data.description
                            .replace(/<\/?[^>]+(>|$)/g, "")
                            .substring(0, 80) + "..."
                          : "Special Paragraph"}
                      </p>
                    </div>
                  </div>
                  </Link>
                );
              })
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default Section;
