import React, { useState, useEffect } from "react";

import FilterCard from "./FilterCard";

import { FaArrowsAltH } from "react-icons/fa";
import { useAppContext } from "../../context/store";
import axios from "axios";
import config from "../../config";


function Mobilefilter({ checkboxRef, maxBudget, setMaxBudget, minBudget, setMinBudget, filters, setFilters, allHotels, filteredHotels, setFilteredHotels }) {
  const [facilities, setFacilities] = useState([])
  const [ammenities, setAmmenities] = useState([])

  useEffect(() => {
    const getFacilities = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/dashboard/facilities`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
           mysecret: config.MY_SECRET,
          },
        }
      );

      setFacilities(response.data.data);
    }

    const getAmmenities = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/dashboard/ammenities`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
           mysecret: config.MY_SECRET,
          },
        }
      );

      setAmmenities(response.data.data);
    }

    getAmmenities();
    getFacilities();
  }, [])


  const handleCheckboxChange = (filterType, value) => {
    setFilters((prevFilters) => {
      const currentFilterType = prevFilters[filterType] || [];

      setMinBudget("");
      setMaxBudget("");

      if (value === "NPR 0 - 1500") {
        setMaxBudget("1500");
      } else if (value === "NPR 1500 - 2500") {
        setMinBudget("1500");
        setMaxBudget("2500");
      } else if (value === "NPR 2500 - 3500") {
        setMinBudget("2500");
        setMaxBudget("3500");
      } else if (value === "NPR 3500 - 4500") {
        setMinBudget("3500");
        setMaxBudget("4500");
      } else if (value === "NPR 4500+") {
        setMinBudget("4500");
        setMaxBudget("");
      }

      return {
        ...prevFilters,
        [filterType]: currentFilterType.includes(value)
          ? currentFilterType.filter((v) => v !== value)
          : [...currentFilterType, value],
      };
    });
  };

  const handleMinBudgetChange = (e) => {
    setMinBudget(e.target.value);
  };

  const handleMaxBudgetChange = (e) => {
    setMaxBudget(e.target.value);
  };

  return (
    <div>
      <div className="w-full md:w-[400px] scroller h-screen overflow-y-auto px-1 mt-2 pb-[200px]  z--10">
        {/* <div>
          <h3 className="text-[20px] text-slate-700 font-[700] px-3 py-2 mb-1">
            Price Per Night
          </h3>
          <div className="px-3">
            <div className="form-check  mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value="Last Minute Deal"
                id="flexCheckDefault"
                ref={checkboxRef}
              />
              <label className="form-check-label" for="flexCheckDefault">
                NPR 0 - 1500
              </label>
            </div>
            <div className="form-check  mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value="5 Star"
                id="flexCheckDefault"
                ref={checkboxRef}
                onChange={() =>
                  handleCheckboxChange("priceRange", "NPR 0 - 1500")
                }
              />
              <label className="form-check-label" for="flexCheckDefault">
                NPR 1500 - 2500
              </label>
            </div>
            <div className="form-check  mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=" North Nepal"
                id="flexCheckDefault"
                ref={checkboxRef}
                onChange={() =>
                  handleCheckboxChange("priceRange", "NPR 2500 - 3500")
                }
              />
              <label className="form-check-label" for="flexCheckDefault">
                NPR 2500 - 3500
              </label>
            </div>
            <div className="form-check  mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=" North Nepal"
                id="flexCheckDefault"
                ref={checkboxRef}
                onChange={() =>
                  handleCheckboxChange("priceRange", "NPR 3500 - 4500")
                }
              />
              <label className="form-check-label" for="flexCheckDefault">
                NPR 3500 - 4500
              </label>
            </div>
            <div className="form-check  mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=" North Nepal"
                id="flexCheckDefault"
                ref={checkboxRef}
                onChange={() => handleCheckboxChange("priceRange", "NPR 4500+")}
              />
              <label className="form-check-label" for="flexCheckDefault">
                NPR 4500+
              </label>
            </div>
          </div>
        </div> */}
        <div>
          <h3 className="text-[20px] text-slate-700 font-[700] px-3 py-2 mb-2">
            Your Budget
          </h3>
          <div className="flex flex-row justify-center items-center p-2 mb-1">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Minimum"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={minBudget}
                onChange={handleMinBudgetChange}
              />
            </div>
            <span className="text-[25px] font-[600] px-1">
              <FaArrowsAltH className="inline" />
            </span>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Maximum"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={maxBudget}
                onChange={handleMaxBudgetChange}
              />
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-[20px] text-slate-700 font-[700] px-3 py-2 mb-1">
            Star Rating
          </h3>
          <div className="px-3">
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value="Last Minute Deal"
                id="flexCheckDefault"
                checked={filters.starRating && filters.starRating.includes("5")}
                onChange={() => handleCheckboxChange("starRating", "5")}
              />
              <label className="form-check-label" for="flexCheckDefault">
                5 Star
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value="5 Star"
                id="flexCheckDefault"
                checked={filters.starRating && filters.starRating.includes("4")}
                onChange={() => handleCheckboxChange("starRating", "4")}
              />
              <label className="form-check-label" for="flexCheckDefault">
                4 Star
              </label>
            </div>
            <div className="form-check  mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=" North Nepal"
                id="flexCheckDefault"
                checked={filters.starRating && filters.starRating.includes("3")}
                onChange={() => handleCheckboxChange("starRating", "3")}
              />
              <label className="form-check-label" for="flexCheckDefault">
                3 Star
              </label>
            </div>
            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="checkbox"
                value=" North Nepal"
                id="flexCheckDefault"
                checked={filters.starRating && filters.starRating.includes("2")}
                onChange={() => handleCheckboxChange("starRating", "2")}
              />
              <label className="form-check-label" for="flexCheckDefault">
                2 Star
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=" North Nepal"
                id="flexCheckDefault"
                checked={filters.starRating && filters.starRating.includes("1")}
                onChange={() => handleCheckboxChange("starRating", "1")}
              />
              <label className="form-check-label" for="flexCheckDefault">
                1 Star
              </label>
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-[20px] text-slate-700 font-[700] px-3 py-2 mb-1">
            Property Type
          </h3>
          <div className="px-3">
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value="Hotel"
                id="Hotel"
                checked={filters.propertyType && filters.propertyType.includes("Hotel")}
                onChange={() => handleCheckboxChange("propertyType", "Hotel")}
              />
              <label className="form-check-label" for="flexCheckDefault">
                Hotel
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value="Apartment"
                id="flexCheckDefault"
                checked={filters.propertyType && filters.propertyType.includes("Apartment")}
                onChange={() => handleCheckboxChange("propertyType", "Apartment")}
              />
              <label className="form-check-label" for="flexCheckDefault">
                Apartment
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value="Villa"
                id="Villa"
                checked={filters.propertyType && filters.propertyType.includes("Villa")}
                onChange={() =>
                  handleCheckboxChange("propertyType", "Villa")
                }
              />
              <label className="form-check-label" for="flexCheckDefault">
                Villa
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=" Homestay"
                id="Homestay"
                checked={filters.propertyType && filters.propertyType.includes("Homestay")}
                onChange={() => handleCheckboxChange("propertyType", "Homestay")}
              />
              <label className="form-check-label" for="flexCheckDefault">
                Homestay
              </label>
            </div>
            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="checkbox"
                value=" Resort"
                id="Resort"
                checked={filters.propertyType && filters.propertyType.includes("Resort")}
                onChange={() => handleCheckboxChange("propertyType", "Resort")}
              />
              <label className="form-check-label" for="flexCheckDefault">
                Resort
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value={["Guest house"]}
                id="Guest house"
                checked={filters.propertyType && filters.propertyType.includes("Guest house")}
                onChange={() => handleCheckboxChange("propertyType", "Guest house")}
              />
              <label className="form-check-label" for="flexCheckDefault">
                Guest house
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value="Hostel"
                id="Hostel"
                checked={filters.propertyType && filters.propertyType.includes("Hostel")}
                onChange={() => handleCheckboxChange("propertyType", "Hostel")}
              />
              <label className="form-check-label" for="flexCheckDefault">
                Hostel
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=" North Nepal"
                id="flexCheckDefaultqq"
                checked={filters.propertyType && filters.propertyType.includes("Camp")}
                onChange={() => handleCheckboxChange("propertyType", "Camp")}
              />
              <label className="form-check-label" for="flexCheckDefault">
                Camp
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=" North Nepal"
                id="flexCheckDefaultww"
                checked={filters.propertyType && filters.propertyType.includes("Tree house")}
                onChange={() =>
                  handleCheckboxChange("propertyType", "Tree house")
                }
              />
              <label className="form-check-label" for="flexCheckDefault">
                Tree house
              </label>
            </div>
          </div>
        </div>
        {/* Guest rating */}
        {/* <div>
                    <h3 className='text-[20px] text-slate-700 font-[700] px-3 py-2 mb-3'>Guest Rating</h3>
                    <div className='px-3'>
                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox" value="5 Star" id="flexCheckDefault" />
                            <label className="form-check-label" for="flexCheckDefault">
                                Excellent
                            </label>
                        </div>
                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox" value=" North Nepal" id="flexCheckDefault" />
                            <label className="form-check-label" for="flexCheckDefault">
                                Very Good
                            </label>
                        </div>
                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox" value=" North Nepal" id="flexCheckDefault" />
                            <label className="form-check-label" for="flexCheckDefault">
                                Good
                            </label>
                        </div>
                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox" value=" North Nepal" id="flexCheckDefault" />
                            <label className="form-check-label" for="flexCheckDefault">
                                No rating
                            </label>
                        </div>
                    </div>
                </div> */}
        {/* Facilities */}
        <div>
          <h3 className="text-[20px] text-slate-700 font-[700] px-3 py-2 mb-1">
            Facilities
          </h3>
          <div className="px-3">
            {facilities.length > 0 && facilities.map(x => {
              return (
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={x.name}
                    id="flexCheckDefaultee"
                    checked={filters.facilities && filters.facilities.includes(x.name)}
                    onChange={() => handleCheckboxChange("facilities", x.name)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {x.name}
                  </label>
                </div>
              )
            })}
          </div>
        </div>
        <div>
          <h3 className="text-[20px] text-slate-700 font-[700] px-3 py-2 mb-1">
            Ammenities
          </h3>
          <div className="px-3">
            {ammenities.length > 0 && ammenities.map(x => {
              return (
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={x.name}
                    id="flexCheckDefaultee"
                    checked={filters.ammenities && filters.ammenities.includes(x.name)}
                    onChange={() => handleCheckboxChange("ammenities", x.name)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {x.name}
                  </label>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mobilefilter;
