import React, { useState, useEffect, useMemo, useRef } from "react";
import { FaUser, FaWifi } from "react-icons/fa";
import { CiWifiOff } from "react-icons/ci";
import { LuParkingCircle } from "react-icons/lu";
import { MdOutlineHomeWork } from "react-icons/md";
import { AiOutlineKey } from "react-icons/ai";
import { FaHouseChimneyCrack } from "react-icons/fa6";
import { GrFormPrevious } from "react-icons/gr";
import { format, isWeekend } from "date-fns";
import { MdOutlineNavigateNext } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { Icon } from "@iconify/react";

import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { DateRangePicker } from "react-date-range";
import { useAppContext } from "../../context/store";
import axios from "axios";
import Button from "react-bootstrap/esm/Button";
import moment from "moment";

function HotelRooms() {
  const { state, actions } = useAppContext();
  const [selectedRooms, setSelectedRooms] = useState({});
  const [show, setShow] = useState(false);
  const hotelData = state.hotelDetails;
  const roomsDeatils = state.roomData;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [arrivalHours, setArrivalHours] = useState("");
  const [arrivalMinutes, setArrivalMinutes] = useState("");
  const [arrivalAmPm, setArrivalAmPm] = useState("AM");
  const [smokingPreference, setSmokingPreference] = useState("");
  const [isWeekEnd, setIsWeekend] = useState(false);
  const [specialRequest, setSpecialRequest] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showCalender, setShowCalender] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [paymentOption, setPaymentOption] = useState("");
  const searchParams = new URLSearchParams(window.location.search);
  const lat = searchParams.get("latitude");
  const lng = searchParams.get("longitude");
  const location = searchParams.get("location");
  const checkIn = searchParams.get("checkIn");
  const children = searchParams.get("children");
  const checkOut = searchParams.get("checkOut");
  const room = searchParams.get("room");
  const adult = searchParams.get("adult");
  const [finalPrice, setFinalPrice] = useState(0);
  const isMobile = window.innerWidth <= 800;
  const [nights, setNights] = useState({
    weekend: 0,
    weekday: 0,
    holidays: searchParams.get("holidays"),
    weekendHolidays: searchParams.get("weekendHolidays")
  });

  const mobileView = {
    padding: "10px 2px",
  };
  const desktopView = {
    padding: "16px",
  };

  const [date, setDate] = useState([
    {
      startDate: moment(checkIn, 'ddd MMM DD YYYY HH:mm:ss [GMT] ZZ (z)').toDate(),
      endDate: moment(checkOut, 'ddd MMM DD YYYY HH:mm:ss [GMT] ZZ (z)').toDate(),
      key: "selection",
    },
  ]);

  const formatStartDate = format(date[0].startDate, "dd.MMM");
  const formatEndDate = format(date[0].endDate, "dd.MMM");

  const handleCalender = () => {
    setShowCalender(true);
    setOpenOptions(false);
  };

  const calendarRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target) &&
        inputRef1.current &&
        !inputRef1.current.contains(event.target) &&
        inputRef2.current &&
        !inputRef2.current.contains(event.target)
      ) {
        setShowCalender(false);
        setOpenOptions(true);
      }
    };

    if (showCalender) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showCalender]);

  const getPriceBasedOnDay = () => {
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);
    const startDayOfWeek = checkInDate.getDay();
    const endDayOfWeek = checkOutDate.getDay();

    let timeDifference = checkOutDate.getTime() - checkInDate.getTime();

    let numberOfNights = Math.ceil(timeDifference / (24 * 60 * 60 * 1000));

    let isWeekend = false;

    isWeekend = startDayOfWeek === 0 || startDayOfWeek === 6;

    let nightsData = {
      weekday: 0,
      weekend: 0,
    };

    if (isWeekend) {
      nightsData.weekend = nightsData.weekend + 1;
    } else {
      nightsData.weekday = nightsData.weekday + 1;
    }

    let day = checkInDate;

    if (numberOfNights > 1) {
      for (let i = 1; i < numberOfNights; i++) {
        day = new Date(day.getTime() + 24 * 60 * 60 * 1000);
        let currentDayOfWeek = day.getDay();

        isWeekend = currentDayOfWeek === 0 || currentDayOfWeek === 6;
        if (isWeekend) {
          nightsData.weekend = nightsData.weekend + 1;
        } else {
          nightsData.weekday = nightsData.weekday + 1;
        }
      }
    }

    nightsData.holidays = parseInt(searchParams.get("holidays"));
    nightsData.weekendHolidays = parseInt(searchParams.get("weekendHolidays"));

    nightsData.weekend = nightsData.weekend - parseInt(searchParams.get("weekendHolidays"));
    nightsData.weekday = nightsData.weekday - parseInt(searchParams.get("holidays"));

    // console.log(nightsData, 'ndndnd');

    setNights(nightsData);
  };

  const hideCalender = () => {
    setShowCalender(false);
    setOpenOptions(true);
  };

  const handleDateSelect = (ranges) => {
    setDate([ranges.selection]);
  };

  const calenderInput = {
    fontSize: "20px",
    fontWeight: "500",
  };

  // console.log(hotelData, "this is hotel data");

  const formatDateWithDayName = (dateObject) => {
    const options = { weekday: "long" };
    return dateObject.toLocaleDateString("en-US", options);
  };
  const currentDay = formatDateWithDayName(date[0].startDate);
  const lastDay = formatDateWithDayName(date[0].endDate);

  const handleArrivalTimeChange = (type, value) => {
    switch (type) {
      case "hours":
        setArrivalHours(value);
        break;
      case "minutes":
        setArrivalMinutes(value);
        break;
      case "amPm":
        setArrivalAmPm(value);
        break;
      default:
        break;
    }
  };

  const handleSmokingPreferenceChange = (value) => {
    setSmokingPreference(value);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (
      JSON.parse(localStorage.getItem("isLoggedIn")) &&
      localStorage.getItem("token") &&
      state.isLoggedIn
    ) {
      if (Object.keys(selectedRooms).length !== 0) {
        setFirstName(state?.profileData?.username?.split(" ")[0]);
        setLastName(state?.profileData?.username?.split(" ")[1]);
        setEmail(state?.profileData?.email);
        setPhoneNumber(state?.profileData?.phoneNumber);
        setShow(true);
      } else {
        toast.warning("Please select a room");
      }
    } else {
      toast.warning("Please Login First");
    }
  };
  const inlineStyle = {
    backgroundColor: "#f3f5f8",
    padding: "10px 10px",
  };

  useEffect(() => {
    getPriceBasedOnDay();
  }, []);

  const IncrementNumber = (roomType, roomId, numberOfRoom, price) => {
    let isUpdate;
    if (
      roomId.endsWith("nr") &&
      selectedRooms[roomId.slice(0, -3)] &&
      selectedRooms[roomId.slice(0, -3)] == numberOfRoom
    ) {
      // console.log("111111");
      isUpdate = false;
    } else if (
      selectedRooms[`${roomId}-nr`] &&
      selectedRooms[`${roomId}-nr`] == numberOfRoom
    ) {
      isUpdate = false;
    } else if (
      roomId.endsWith("nr") &&
      selectedRooms[roomId] &&
      selectedRooms[roomId.slice(0, -3)]
    ) {
      isUpdate =
        selectedRooms[roomId] + selectedRooms[roomId.slice(0, -3)] ==
          numberOfRoom
          ? false
          : true;
    } else if (selectedRooms[roomId] && selectedRooms[`${roomId}-nr`]) {
      isUpdate =
        selectedRooms[roomId] + selectedRooms[`${roomId}-nr`] == numberOfRoom
          ? false
          : true;
    } else {
      isUpdate = true;
    }

    if (isUpdate) {
      setSelectedRooms((prevSelectedRooms) => {
        const updatedRooms = { ...prevSelectedRooms };

        const currentCount = updatedRooms[roomId] || 0;

        if (currentCount < numberOfRoom) {
          updatedRooms[roomId] = currentCount + 1;
        } else {
          // If trying to exceed, keep it at the maximum available number
          updatedRooms[roomId] = numberOfRoom;
        }

        return updatedRooms;
      });

      const rooms = { ...selectedRooms };
      const count = rooms[roomId] || 0;

      let data = (count + 1) * parseFloat(price);

      setFinalPrice((pValue) => {
        return {
          ...pValue,
          [roomId]: {
            price: data,
            room: roomType,
            noOfRooms: count + 1,
          },
        };
      });
    }
  };

  useEffect(() => {
    // console.log(finalPrice, selectedRooms, 'lllllllllllllll');
  }, [finalPrice, selectedRooms])

  const DecreaseNumber = (roomType, roomId, price) => {
    setSelectedRooms((prevSelectedRooms) => {
      const updatedRooms = { ...prevSelectedRooms };
      updatedRooms[roomId] = Math.max((updatedRooms[roomId] || 0) - 1, 0);

      // If the value becomes 0, remove the property
      if (updatedRooms[roomId] <= 0) {
        delete updatedRooms[roomId];
      }

      return updatedRooms;
    });

    const rooms = { ...selectedRooms };
    const count = rooms[roomId] || 0;

    let data = 0;

    if (count > 0) {
      data = (count - 1) * parseFloat(price);
    }

    setFinalPrice((pValue) => {
      return {
        ...pValue,
        [roomId]: {
          price: data,
          room: roomType,
          noOfRooms: count<=0 ? 0 : count - 1,
        },
      };
    });
  };
  const priceCal = () => {
    let sum = 0;

    for (let key in finalPrice) {
      if (finalPrice.hasOwnProperty(key)) {
        const value = finalPrice[key].price;
        if (typeof value === "number") {
          sum += value;
        }
      }
    }

    return sum;
  };

  const renderAmenities = (amenities) => {
    return state.hotelDetails.ammenities.map((amenity, index) => (
      <p
        key={index}
        className=" d-flex mb-1 text-[11px] md:text-[19px] xl:text-[17px]"
      >
        <span>
          <Icon
            icon={amenity.icon}
            className="font-[800] text-sm md:text-xl text-[#129035] mr-1"
          />
        </span>
        {amenity.name}
      </p>
    ));
  };

  const handleSubmit = async () => {
    // Check if selected rooms are empty
    const selectedRoomsArray = Object.entries(selectedRooms)
      .filter(([roomId, count]) => count > 0)
      .map(([roomId, count]) => ({
        roomId: roomId.endsWith("nr") ? roomId.slice(0, -3) : roomId,
        count,
        refundable: roomId.endsWith("nr") ? false : true,
      }));

    if (selectedRoomsArray.length === 0) {
      toast.warning("Please Select Room");
      return;
    }

    const estimatedArrival = `${arrivalHours}:${arrivalMinutes} ${arrivalAmPm}`;
    // Check if other required fields are empty
    const requiredFields = [
      { value: firstName, label: "First Name" },
      { value: lastName, label: "Last Name" },
      { value: email, label: "Email" },
      { value: phoneNumber, label: "Phone Number" },
      { value: estimatedArrival, label: "estimatedArrival Number" },
      { value: smokingPreference, label: "smokingPreference Number" },
      // { value: specialRequest, label: "specialRequest Number" },
    ];

    const missingField = requiredFields.find((field) => !field.value);

    if (missingField) {
      toast.warning(`Please fill in the ${missingField.label} field`);
      return;
    }

    // console.log(hotelData?.paymentPolicy[0]?.canclePeriod, "payment");

    const formData = {
      firstName,
      lastName,
      email,
      phoneNumber,
      estimatedArrival,
      smokingPreference,
      specialRequest,
      hotel_id: hotelData._id,
      rooms: selectedRoomsArray,
      checkInDate: date[0].startDate,
      checkOutDate: date[0].endDate,
      user_id: state?.profileData?._id,
      status: "confirmed",
      price: priceCal(),
      cancellationDeadline:
        hotelData?.paymentPolicy[0]?.canclePeriod !== ""
          ? hotelData?.paymentPolicy[0]?.canclePeriod
          : null,
      rescheduledDealine:
        hotelData?.paymentPolicy[0]?.reschedulePeriod !== ""
          ? hotelData?.paymentPolicy[0]?.reschedulePeriod
          : null,
    };

    try {
      const token = localStorage.getItem("token");

      const headers = {
        Authorization: token ? `Bearer ${token}` : undefined,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/reservation`,
        formData,
        { headers }
      );

      if (response.data.status) {
        toast.success("Reservation Successful");

        // Reset the component's state to initial values or empty values
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setArrivalHours("");
        setArrivalMinutes("");
        setArrivalAmPm("AM");
        setSmokingPreference("");
        setSpecialRequest("");
        setDate([
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ]);
        setIsWeekend(false);
        setSelectedRooms({});
      } else {
        toast.error("Reservation Failed");
      }

      handleClose();
    } catch (error) {
      console.error(error, "error");
      toast.error("Reservation Failed. Please Try Again");
      handleClose();
    }
  };

  const formatPriceNumber = useMemo(() => {
    return (price, currency) => {
      // console.log(price, 'pppppppp');
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency,
      }).format(price);
    };
  }, []);  

  return (
    <div className="relative pt-3 pb-5 mb-4 px-3">
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Body style={{ padding: "15px" }}>
          <Row>
            <div className="mb-3">
              <h3 className="text-[18px] sm:text-3xl font-[700] tracking-wider">
                Enter Your Details
              </h3>
            </div>
          </Row>
          <Row>
            <Col>
              <div class="input-group mb-3">
                <input
                  type="text"
                  style={inlineStyle}
                  className="form-control text-[10px] sm:text-sm placeholder:text-sm"
                  placeholder="First Name"
                  aria-label="First Name"
                  aria-describedby="basic-addon1"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
            </Col>
            <Col>
              <div class="input-group mb-3">
                <input
                  type="text"
                  style={inlineStyle}
                  className="form-control text-[10px] md:text-sm placeholder:text-sm"
                  placeholder="Last Name"
                  aria-label="Last Name"
                  aria-describedby="basic-addon1"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="input-group mb-3">
                <input
                  type="number"
                  style={inlineStyle}
                  className="form-control text-[10px] sm:text-sm placeholder:text-sm sm:placeholder:text-[18px]"
                  placeholder="Phone Number"
                  aria-label="Number"
                  aria-describedby="basic-addon1"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </Col>
            <Col>
              <div class="input-group mb-3">
                <input
                  type="Email"
                  style={inlineStyle}
                  className="form-control text-[10px] sm:text-sm placeholder:text-sm sm:placeholder:text-[18px]"
                  placeholder="Email"
                  aria-label="email"
                  aria-describedby="basic-addon1"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <div className="input-group mb-3">
              <div className="w-[60px] sm:w-[100px] h-[55px] bg-[#f3f5f8]  rounded-md text-center pt-3">
                <span
                className="text-[9px] w-full h-full md:text-sm font-[600] text-black"
                >
                  Arrival Time
                </span>
              </div>
              <input
                type="number"
                style={inlineStyle}
                className="form-control mx-1 placeholder:text-sm sm:placeholder:text-xl"
                placeholder="Hours"
                aria-label="Arrival Hours"
                aria-describedby="basic-addon1"
                value={arrivalHours}
                onChange={(e) =>
                  handleArrivalTimeChange("hours", e.target.value)
                }
              />
              <span className="mx-1 font-[600] text-[35px]">:</span>
              <input
                type="number"
                style={inlineStyle}
                className="form-control mx-1 placeholder:text-sm sm:placeholder:text-[18px]"
                placeholder="Minutes"
                aria-label="Arrival Minutes"
                aria-describedby="basic-addon1"
                value={arrivalMinutes}
                onChange={(e) =>
                  handleArrivalTimeChange("minutes", e.target.value)
                }
              />
              <select
                className="form-select"
                aria-label="Arrival AM/PM"
                value={arrivalAmPm}
                onChange={(e) =>
                  handleArrivalTimeChange("amPm", e.target.value)
                }
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </Row>

          {/* chekin chekout adding */}
          <Row className="mx-auto mt-1 mb-3 w-full bg-[#f3f5f8]  rounded-md p-1">
            <div className="col">
              <p className="bg-[#fff] w-[100px] text-[#f62c31] text-sm text-center font-bold py-[2px] rounded-lg">
                Check-In
              </p>
              <Form ref={inputRef1} onClick={handleCalender}>
                <input
                  type="text"
                  style={calenderInput}
                  className="form-control bg-transparent"
                  id="floatingInputValue"
                  placeholder="check-In"
                  value={formatStartDate}
                />
                <p className="mt-1 ml-[1px]">{currentDay}</p>
              </Form>
            </div>
            <div className="col">
              <p className="bg-[#fff] w-[100px] text-sm font-bold text-[#f62c31] text-center py-[2px] rounded-lg">
                Check-Out
              </p>
              <Form ref={inputRef2} onClick={handleCalender}>
                <input
                  type="text"
                  style={calenderInput}
                  className="form-control bg-transparent"
                  id="floatingInputValue"
                  placeholder="check-out"
                  value={formatEndDate}
                />
                <p className="mt-1 ml-[1px]">{lastDay}</p>
              </Form>
            </div>
            {/* {showCalender ? (
              <div ref={calendarRef} className="absolute top-[400px] left-0 md:left-12 z-10 w-fit">
                <DateRangePicker
                  editableDateInputs={true}
                  showSelectionPreview={true}
                  calendarFocus="forwards"
                  onChange={handleDateSelect}
                  moveRangeOnFirstSelection={false}
                  ranges={date}
                  preventSnapRefocus={true}
                  months={2}
                  direction={
                    window.innerWidth < 1280 ? "vertical" : "horizontal"
                  }
                  rangeColors={["#f33e5b", "#3ecf8e", "#fed14c"]}
                />
              </div>
            ) : (
              ""
            )} */}
          </Row>

          <Row className="mb-2 px-2">
            <p className="text-[16px] sm:text-2xl font-[500] tracking-wider mb-1">
              Do you Prefer Smoking Room ?{" "}
            </p>
            <div className="px-3">
              <div className="form-check mb-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name="smokingPreference"
                  id="smokingYes"
                  value="Yes"
                  checked={smokingPreference === "Yes"}
                  onChange={() => handleSmokingPreferenceChange("Yes")}
                />
                <label
                  className="form-check-label text-sm sm:text-[20px] font-[400]"
                  htmlFor="smokingYes"
                >
                  Yes
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="smokingPreference"
                  id="smokingNo"
                  value="No"
                  checked={smokingPreference === "No"}
                  onChange={() => handleSmokingPreferenceChange("No")}
                />
                <label
                  className="form-check-label text-sm  sm:text-[20px] font-[400]"
                  htmlFor="smokingNo"
                >
                  No
                </label>
              </div>
            </div>
          </Row>
          <Row>
            <div className="mb-3">
              <h3 className="text-[16px] sm:text-2xl font-[500] tracking-wider">
                Special Request
              </h3>
              <textarea
                name="textarea"
                className="w-full h-[100px] border-[1px] border-slate-300 bg-[#f3f5f8] outline-none rounded-md px-2 py-1"
                placeholder="Any Special Requests"
                value={specialRequest}
                onChange={(e) => setSpecialRequest(e.target.value)}
              ></textarea>
            </div>
          </Row>

          <Row>
            <div className="mb-3">
              <div>
                {Object.keys(finalPrice).map((mainKey) => (
                  finalPrice[mainKey].noOfRooms > 0 && 
                  <div className="mb-2 font-[500]" key={mainKey}>
                    <div className="">
                      {finalPrice[mainKey].noOfRooms +
                        " x " +
                        finalPrice[mainKey].room}
                    </div>
                  </div>
                ))}
              </div>
              <h4 className="text-[18px] sm:text-2xl font-[500] tracking-wider">
                Grand Total: {state.currency} {priceCal().toFixed(2)}
              </h4>
            </div>
          </Row>

          <Row>
            <div className="mb-3">
              <h3 className="text-[18px] sm:text-2xl font-[500] tracking-wider">
                Payment Option
              </h3>
              <div className="px-3">
                {hotelData?.paymentPolicy[0]?.paymentOption === "Both" ||
                  "Pay at hotel" ? (
                  <>
                    {" "}
                    <div className="form-check mb-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="paymentOption"
                        id="payAtHotel"
                        value="Pay at Hotel"
                        checked={paymentOption === "Pay at Hotel"}
                        onChange={() => setPaymentOption("Pay at Hotel")}
                      />
                      <label
                        className="form-check-label text-sm sm:text-[20px] font-[400]"
                        htmlFor="payAtHotel"
                      >
                        Pay at Hotel
                      </label>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </Row>

          <div className="w-full mx-auto flex flex-row justify-end items-center">
            <Button className="btn btn-danger mx-2" onClick={handleSubmit}>
              Proceed
            </Button>
            <Button className="btn btn-danger mx-2" onClick={handleClose}>
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {state.roomData?.length > 0 ? (
        <>
          {state.roomData?.map((room) => (
            <>
              <div
                className="w-full bg-slate-50  my-4  grid grid-cols-2 lg:grid-cols-4 gap-4  rounded-xl shadow-md h-auto"
                key={room._id}
                style={isMobile ? mobileView : desktopView}
              >
                {/* Details from hotelData */}
                <div className="border-r-[1px] px-3 border-slate-400">
                  <h3 className="text-[20px] md:text-[23px] font-[600] mb-0 text-[#F62C31]">
                    {room?.roomType}
                  </h3>
                  <div className="mt-2">
                    {/* Render icons based on data */}
                    {Array.from({ length: room?.guests }).map((_, index) => (
                      <FaUser
                        key={index}
                        className="inline mx-1 text-sm sm:text-[20px]"
                      />
                    ))}
                  </div>
                  <p className="mb-1 mt-2 text-[12px] md:text-[20px] xl:text-[17px]">
                    <span className="text-[12px] md:text-[20px] xl:text-[17px]">
                      -
                    </span>{" "}
                    Room Type : {room?.roomType}
                  </p>
                  <p className="mb-1  text-[12px] md:text-[20px] xl:text-[17px]">
                    <span className="text-[12px] md:text-[20px] xl:text-[17px]">
                      -
                    </span>{" "}
                    Free Cancellation
                  </p>
                  <p className="mb-1  text-[12px] md:text-[20px] xl:text-[17px]">
                    <span className="text-[12px] md:text-[20px] xl:text-[17px]">
                      -
                    </span>{" "}
                    {hotelData?.hotelRules?.allowChildren === "no"
                      ? "Not Child Friendly"
                      : "Child Friendly"}
                  </p>
                  {room?.isBreakFastAvailable === "yes" ? (
                    <>
                      <p className="mb-1  text-[12px] md:text-[20px] xl:text-[17px]">
                        <span className="text-[12px] md:text-[20px] xl-[17px]">
                          -
                        </span>{" "}
                        Breakfast Included
                      </p>
                    </>
                  ) : null}

                  {hotelData?.facilities?.recreation?.map((item, idx) => (
                    <>
                      <p className="mb-1  text-[12px] md:text-[17px]" key={idx}>
                        <span className="text-[12px] md:text-[16px]">-</span>{" "}
                        {item}
                      </p>
                    </>
                  ))}
                </div>
                <div className="border-r-[0px] lg:border-r-[1px] px-4 border-slate-400">
                  <h3 className="text-[20px] md:text-[23px] font-[600] mb-3 text-[#F62C31]">
                    Amenities
                  </h3>
                  {renderAmenities([
                    hotelData?.parking?.isParkingAvailable === "yesFree" && {
                      icon: <LuParkingCircle className="inline mr-2" />,
                      label: "Free Parking",
                    },
                    {
                      icon: <MdOutlineHomeWork className="inline mr-2" />,
                      label: "Restaurant",
                    },
                    {
                      icon: <FaWifi className="inline mr-2" />,
                      label: "Free Wifi",
                    },

                    {
                      icon: <AiOutlineKey className="inline mr-2" />,
                      label: "Key Card Access",
                    },
                    {
                      icon: <FaHouseChimneyCrack className="inline mr-2" />,
                      label: "Housekeeping",
                    },
                  ])}
                </div>

                <div className="pl-5 border-r-[1px] border-slate-400">
                  <h3 className="text-[23px] font-[600] mb-2 text-[#F62C31]">
                    Price
                  </h3>
                  <p className="mb-2 text-[20px] font-[400]">
                    {" "}
                    {formatPriceNumber(
                      nights.weekendHolidays > 0
                        ? (
                          (room?.weekendPrice +
                            (room?.weekendPrice * (room?.holidayPrice || 0)) / 100) *
                          state.rate
                        ).toFixed(2) : nights.holidays > 0 ? (
                          (room?.weekdayPrice +
                            (room?.weekdayPrice * (room?.holidayPrice || 0)) / 100) *
                          state.rate
                        ).toFixed(2) :
                          nights.weekend > 0
                            ? (
                              room?.weekendPrice *
                              state.rate
                            ).toFixed(2)
                            : (
                              room?.weekdayPrice *
                              state.rate
                            ).toFixed(2),
                      state.currency
                    )}
                  </p>
                  <p className="mb-2 text-slate-500 text-[15px] sm:text-[20px] md:text-[22px] xl:text-[17px]">
                    +
                    {formatPriceNumber(
                      nights.weekendHolidays > 0
                        ? (
                          ((room?.weekendPrice +
                            (room?.weekendPrice * (room?.holidayPrice || 0)) / 100) *
                            state.rate * 13) / 100
                        ).toFixed(2) : nights.holidays > 0 ? (
                          ((room?.weekdayPrice +
                            (room?.weekdayPrice * (room?.holidayPrice || 0)) / 100) *
                            state.rate * 13) / 100
                        ).toFixed(2) :
                          nights.weekend > 0
                            ? (
                              (room?.weekendPrice *
                                state.rate * 13) / 100
                            ).toFixed(2)
                            : (
                              (room?.weekdayPrice *
                                state.rate * 13) / 100
                            ).toFixed(2),
                      state.currency
                    )}{" "}
                    Taxes & Fees
                  </p>
                  <p className="mb-2 text-slate-500 text-[15px] sm:text-[20px] md:text-[22px xl:text-[17px]">
                    Per Night
                  </p>
                </div>
                <div className="px-3">
                  <h3 className="text-[20px] md:text-[25px] font-[600] text-[#F62C31]">
                    No of Rooms
                  </h3>
                  <div className="flex flex-row items-center mb-3">
                    <button
                      onClick={() =>
                        DecreaseNumber(
                          room.roomType,
                          room._id,
                          (
                            (room?.weekdayPrice * state.rate +
                              (room?.weekdayPrice * state.rate * 13) / 100) *
                            nights.weekday +
                            (room?.weekendPrice * state.rate +
                              (room?.weekendPrice * state.rate * 13) / 100) *
                            nights.weekend +
                            ((room?.weekdayPrice + ((room?.weekdayPrice * (room?.holidayPrice || 0))/100)) * state.rate +
                              ((room?.weekdayPrice + ((room?.weekdayPrice * (room?.holidayPrice || 0))/100)) * state.rate * 13) / 100) *
                            nights.holidays +
                            ((room?.weekendPrice + ((room?.weekdayPrice * (room?.holidayPrice || 0))/100)) * state.rate +
                              ((room?.weekendPrice + ((room?.weekdayPrice * (room?.holidayPrice || 0))/100)) * state.rate * 13) / 100) *
                            nights.weekendHolidays
                          )
                        )
                      }
                    >
                      <GrFormPrevious className="text-[25px] font-[700]" />
                    </button>
                    <span className="bg-slate-600 rounded-lg px-2 text-[#fff] text-[16px]">
                      {selectedRooms[room._id] || 0}
                    </span>
                    <button
                      onClick={() => {
                        IncrementNumber(
                          room.roomType,
                          room._id,
                          room?.noOfRooms - room?.totalRoomsBooked < 0
                            ? 0
                            : room?.noOfRooms - room?.totalRoomsBooked,
                          (
                            (room?.weekdayPrice * state.rate +
                              (room?.weekdayPrice * state.rate * 13) / 100) *
                            nights.weekday +
                            (room?.weekendPrice * state.rate +
                              (room?.weekendPrice * state.rate * 13) / 100) *
                            nights.weekend +
                            ((room?.weekdayPrice + ((room?.weekdayPrice * (room?.holidayPrice || 0))/100)) * state.rate +
                              ((room?.weekdayPrice + ((room?.weekdayPrice * (room?.holidayPrice || 0))/100)) * state.rate * 13) / 100) *
                            nights.holidays +
                            ((room?.weekendPrice + ((room?.weekdayPrice * (room?.holidayPrice || 0))/100)) * state.rate +
                              ((room?.weekendPrice + ((room?.weekdayPrice * (room?.holidayPrice || 0))/100)) * state.rate * 13) / 100) *
                            nights.weekendHolidays
                          )
                        );
                      }}
                    >
                      <MdOutlineNavigateNext className="text-[25px]" />
                    </button>
                    <span
                      className={`${selectedRooms[room._id]
                        ? "bg-[#129035]"
                        : "bg-slate-600"
                        } text-[13px] text-[#fff] px-2 py-1 ml-0 md:ml-2 rounded-lg cursor-pointer`}
                    >
                      {selectedRooms[room._id] ? "selected" : "Select"}
                    </span>
                  </div>
                  <p className="text-[18px] sm:text-[21px] font-[700] mb-1">
                    {formatPriceNumber(
                      !selectedRooms.hasOwnProperty(`${room._id}`)
                        ? 0
                        : (
                          (
                            (room?.weekdayPrice * state.rate +
                              (room?.weekdayPrice * state.rate * 13) / 100) *
                            nights.weekday +
                            (room?.weekendPrice * state.rate +
                              (room?.weekendPrice * state.rate * 13) / 100) *
                            nights.weekend +
                            ((room?.weekdayPrice + ((room?.weekdayPrice * (room?.holidayPrice || 0))/100)) * state.rate +
                              ((room?.weekdayPrice + ((room?.weekdayPrice * (room?.holidayPrice || 0))/100)) * state.rate * 13) / 100) *
                            nights.holidays +
                            ((room?.weekendPrice + ((room?.weekdayPrice * (room?.holidayPrice || 0))/100)) * state.rate +
                              ((room?.weekendPrice + ((room?.weekdayPrice * (room?.holidayPrice || 0))/100)) * state.rate * 13) / 100) *
                            nights.weekendHolidays
                          ) *
                          selectedRooms[room._id]
                        ).toFixed(2),
                      state.currency
                    )}
                  </p>
                  <p className="text-[21px]">Total</p>
                </div>
              </div>
              <div
                className="w-full bg-slate-50  mt-2 mb-3 grid grid-cols-2 lg:grid-cols-4 gap-4  rounded-xl shadow-md h-auto"
                key={room._id}
                style={isMobile ? mobileView : desktopView}
              >
                {/* Details from hotelData */}
                <div className="border-r-[1px] px-3 border-slate-400">
                  <h3 className="text-[20px] md:text-[23px] font-[600] mb-0 text-[#F62C31]">
                    {room?.roomType}
                  </h3>
                  <div className="mt-2">
                    {/* Render icons based on data */}
                    {Array.from({ length: room?.guests }).map((_, index) => (
                      <FaUser
                        key={index}
                        className="inline mx-1 text-sm sm:text-[20px]"
                      />
                    ))}
                  </div>
                  <p className="mb-1 mt-2 text-[12px] md:text-[20px] xl:text-[17px]">
                    <span className="text-[12px] md:text-[20px] xl:text-[17px]">
                      -
                    </span>{" "}
                    Room Type : {room?.roomType}
                  </p>
                  <p className="mb-1  text-[12px] md:text-[20px] xl:text-[17px]">
                    <span className="text-[12px] md:text-[20px] xl:text-[17px]">
                      -
                    </span>{" "}
                    {/* {hotelData?.paymentPolicy[0]?.cancleOption === "Yes"
                      ? "Free Cancellation"
                      : "Non Refundable"} */}
                    Non Refundable
                  </p>
                  <p className="mb-1  text-[12px] md:text-[20px] xl:text-[17px]">
                    <span className="text-[12px] md:text-[20px] xl:text-[17px]">
                      -
                    </span>{" "}
                    {hotelData?.hotelRules?.allowChildren === "no"
                      ? "Not Child Friendly"
                      : "Child Friendly"}
                  </p>
                  {room?.isBreakFastAvailable === "yes" ? (
                    <>
                      <p className="mb-1  text-[12px] md:text-[20px] xl:text-[17px]">
                        <span className="text-[12px] md:text-[20px] xl-[17px]">
                          -
                        </span>{" "}
                        Breakfast Included
                      </p>
                    </>
                  ) : null}
                  {hotelData?.facilities?.recreation?.map((item, idx) => (
                    <>
                      <p className="mb-1  text-[12px] md:text-[17px]" key={idx}>
                        <span className="text-[12px] md:text-[16px]">-</span>{" "}
                        {item}
                      </p>
                    </>
                  ))}
                </div>
                <div className="border-r-[0px] lg:border-r-[1px] px-4 border-slate-400">
                  <h3 className="text-[20px] md:text-[23px] font-[600] mb-3 text-[#F62C31]">
                    Amenities
                  </h3>
                  {renderAmenities([
                    hotelData?.parking?.isParkingAvailable === "yesFree" && {
                      icon: <LuParkingCircle className="inline mr-2" />,
                      label: "Free Parking",
                    },
                    {
                      icon: <MdOutlineHomeWork className="inline mr-2" />,
                      label: "Restaurant",
                    },
                    {
                      icon: <FaWifi className="inline mr-2" />,
                      label: "Free Wifi",
                    },

                    {
                      icon: <AiOutlineKey className="inline mr-2" />,
                      label: "Key Card Access",
                    },
                    {
                      icon: <FaHouseChimneyCrack className="inline mr-2" />,
                      label: "Housekeeping",
                    },
                  ])}
                </div>

                <div className="pl-5 border-r-[1px] border-slate-400">
                  <h3 className="text-[23px] font-[600] mb-2 text-[#F62C31]">
                    Price
                  </h3>
                  <p className="mb-2 text-[20px] font-[400]">
                    {" "}
                    {formatPriceNumber(
                      nights.weekendHolidays > 0
                        ? (
                          (room?.nonRefundPrice +
                            (room?.nonRefundPrice * (room?.holidayPrice || 0)) / 100) *
                          state.rate
                        ).toFixed(2) : nights.holidays > 0 ? (
                          (room?.nonRefundPrice +
                            (room?.nonRefundPrice * (room?.holidayPrice || 0)) / 100) *
                          state.rate
                        ).toFixed(2) :
                          nights.weekend > 0
                            ? (
                              room?.nonRefundPrice *
                              state.rate
                            ).toFixed(2)
                            : (
                              room?.nonRefundPrice *
                              state.rate
                            ).toFixed(2),
                      state.currency
                    )}
                  </p>
                  <p className="mb-2 text-slate-500 text-[16px] sm:text-[18px] md:text-[20px] xl:text-[17px]">
                    (Non Refundable)
                  </p>
                  <p className="mb-2 text-slate-500 text-[15px] sm:text-[20px] md:text-[22px] xl:text-[17px]">
                    +
                    {formatPriceNumber(
                      nights.weekendHolidays > 0
                        ? (
                          ((room?.nonRefundPrice +
                            (room?.nonRefundPrice * (room?.holidayPrice || 0)) / 100) *
                            state.rate * 13) / 100
                        ).toFixed(2) : nights.holidays > 0 ? (
                          ((room?.nonRefundPrice +
                            (room?.nonRefundPrice * (room?.holidayPrice || 0)) / 100) *
                            state.rate * 13) / 100
                        ).toFixed(2) :
                          nights.weekend > 0
                            ? (
                              (room?.nonRefundPrice *
                                state.rate * 13) / 100
                            ).toFixed(2)
                            : (
                              (room?.nonRefundPrice *
                                state.rate * 13) / 100
                            ).toFixed(2),
                      state.currency
                    )}{" "}
                    Taxes & Fees
                  </p>
                  <p className="mb-2 text-slate-500 text-[15px] sm:text-[20px] md:text-[22px xl:text-[17px]">
                    Per Night
                  </p>
                </div>
                <div className="px-3">
                  <h3 className="text-[20px] md:text-[23px] font-[600] text-[#F62C31]">
                    No of Rooms
                  </h3>
                  <div className="flex flex-row items-center mb-3">
                    <button
                      onClick={() =>
                        DecreaseNumber(
                          `${room.roomType} (non-refundable)`,
                          `${room._id}-nr`,
                          (
                            (room?.nonRefundPrice * state.rate +
                              (room?.nonRefundPrice * state.rate * 13) / 100) *
                            (nights.weekday + nights.weekend)  +
                            ((room?.nonRefundPrice + ((room?.nonRefundPrice * (room?.holidayPrice || 0))/100)) * state.rate +
                              ((room?.nonRefundPrice + ((room?.nonRefundPrice * (room?.holidayPrice || 0))/100)) * state.rate * 13) / 100) *
                            (nights.holidays + nights.weekendHolidays)
                          )
                        )
                      }
                    >
                      <GrFormPrevious className="text-[25px] font-[700]" />
                    </button>
                    <span className="bg-slate-600 rounded-lg px-2 text-[#fff] text-[16px]">
                      {selectedRooms[`${room._id}-nr`] || 0}
                    </span>
                    <button
                      onClick={() => {
                        IncrementNumber(
                          `${room.roomType} (non-refundable)`,
                          `${room._id}-nr`,
                          room?.noOfRooms - room?.totalRoomsBooked < 0
                            ? 0
                            : room?.noOfRooms - room?.totalRoomsBooked,
                          (
                            (room?.nonRefundPrice * state.rate +
                              (room?.nonRefundPrice * state.rate * 13) / 100) *
                            (nights.weekday + nights.weekend)  +
                            ((room?.nonRefundPrice + ((room?.nonRefundPrice * (room?.holidayPrice || 0))/100)) * state.rate +
                              ((room?.nonRefundPrice + ((room?.nonRefundPrice * (room?.holidayPrice || 0))/100)) * state.rate * 13) / 100) *
                            (nights.holidays + nights.weekendHolidays)
                          )
                        );
                      }}
                    >
                      <MdOutlineNavigateNext className="text-[25px]" />
                    </button>
                    <span
                      className={`${selectedRooms[`${room._id}-nr`]
                        ? "bg-[#129035]"
                        : "bg-slate-600"
                        } text-[13px] text-[#fff] px-2 py-1 ml-0 md:ml-2 rounded-lg cursor-pointer`}
                    >
                      {selectedRooms[`${room._id}-nr`] ? "selected" : "Select"}
                    </span>
                  </div>
                  <p className="text-[18px] sm:text-[21px] font-[700] mb-1">
                    {formatPriceNumber(
                      !selectedRooms.hasOwnProperty(`${room._id}-nr`)
                        ? 0
                        : (
                          (
                            (room?.nonRefundPrice * state.rate +
                              (room?.nonRefundPrice * state.rate * 13) / 100) *
                            (nights.weekday + nights.weekend)  +
                            ((room?.nonRefundPrice + ((room?.nonRefundPrice * (room?.holidayPrice || 0))/100)) * state.rate +
                              ((room?.nonRefundPrice + ((room?.nonRefundPrice * (room?.holidayPrice || 0))/100)) * state.rate * 13) / 100) *
                            (nights.holidays + nights.weekendHolidays)
                          ) *
                          selectedRooms[`${room._id}-nr`]
                        ).toFixed(2),
                      state.currency
                    )}
                  </p>
                  <p className="text-[21px]">Total</p>
                </div>
              </div>
            </>
          ))}
        </>
      ) : (
        <>
          <div className="w-full text-center">
            <p className="text-center">no room founds</p>
          </div>
        </>
      )}
      <div className="absolute right-3 bottom-0 py-0">
        <Button
          className="btn btn-danger px-2 py-2 rounded-lg text-[#fff] font-[600]"
          onClick={handleShow}
        >
          Book Now
        </Button>
      </div>
    </div>
  );
}

export default HotelRooms;
