import React, { useEffect, useState } from 'react'
import { FaLocationDot } from "react-icons/fa6";
import { useAppContext } from "../../context/store";
import { Icon } from '@iconify/react';

function HotelAnimites() {

    const mobileAligment = {
        textAlign: 'center'
    };

    const { state } = useAppContext();
    const hotelDetial = state.hotelDetails;

    const hotelData = {
        privateParking: 'Private parking', // Replace with actual data
        freeWifi: hotelDetial?.facilities?.accommodation?.includes('Free Wifi') ? 'Available' : 'Not Available',
        airportShuttle: hotelDetial?.transportation?.providePickUpService === 'yes',
        familyRoom: hotelDetial?.hotelRules?.allowChildren === 'yes',
        nonSmokingRoom: hotelDetial?.facilities?.accommodation?.includes('Non Smoking room') ? 'Available' : 'Not Available',
        restaurant: hotelDetial?.facilities?.accommodation?.includes('Restaurant') ? 'Available' : 'Not Available',
        twentyFourHourFrontDesk: hotelDetial?.facilities?.accommodation?.includes('24-hour front desk') ? true : false,
        bar: hotelDetial?.facilities?.accommodation?.includes('Bar') ? 'Available' : 'Not Available',
        elevator: hotelDetial?.facilities?.accommodation?.includes('Elevator') ? true : false,
        freeBreakfast: hotelDetial?.facilities?.accommodation?.includes('Free Breakfast') ? 'Available' : 'Not Available',
        nearbyLocations: hotelDetial?.nearbyLocations ?? ['Kathmandu Park (2km)', 'Teo Park (2.3km)'],
    };

    useEffect(() => {

    }, []);
    return (
        <div className='p-3'>
            <div className='min-w-fit lg:max-w-full bg-[#fff] rounded-lg my-2 px-3 py-5'>
            {/* Amenities Provides */}
            <div className='min-w-fit lg:max-w-[1000px]  mx-auto'>
                <h2 className='font-[600] mb-4'>Amenities Provided</h2>
                <div style={mobileAligment} className='grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-2 md:gap-4'>
                    {state.hotelDetails.ammenities.length > 0 && state.hotelDetails.ammenities.map(x => {
                        return (
                            <div className='flex flex-row items-center justify-start'>
                                <Icon icon={x.icon} className='font-[800] text-xl text-[#129035]' />
                                <span className='ml-1 text-[16px] font-[500] tracking-wider'>{x.name}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
            {/* Locations Provides */}
            <div className='min-w-fit lg:max-w-[1000px]  mx-auto my-5'>
                <h2 className='font-[600] mb-4'>What's Nearby?</h2>
                <div style={mobileAligment} className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4'>
                    {state.hotelDetails.nearby.map((location, index) => (
                        <div key={index} className='flex flex-row items-center justify-start'>
                            <span><FaLocationDot className='font-[800] text-xl text-slate-900' /></span>
                            <span className='ml-1 text-[16px] font-[500] tracking-wider'>{location.area} ({location.distance} km)</span>
                        </div>
                    ))}
                </div>
            </div>
            </div>
        </div>
    );
}

export default HotelAnimites;
