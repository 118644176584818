import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import Picture from "../../Assets/img/Rectangle.png";
import axios from "axios";
import config from "../../config";
import { useAppContext } from "../../context/store";
import { useNavigate } from "react-router-dom";
import ClipLoader from 'react-spinners/ClipLoader'
// Import the following library to sanitize HTML
// import DOMPurify from "dompurify";

function Hotel() {
  const { actions, state } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const url = state.link
  const mylink =  url.slice(8);
  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const token = localStorage.getItem("token");

        const headers = {
          Authorization: token ? `Bearer ${token}` : undefined,
         mysecret: config.MY_SECRET,
        };

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/hotel/get-all-hotel`,
          {
            headers,
          }
        );

        // console.log(response)

        if (response.data.isHotelAccess) {
          // while the user is not logged in
          actions.setHotel(response.data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching hotels:", error);
        // Handle error as needed
      }
    };

    fetchHotels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function removeHtmlTags(htmlString) {
    // Create a temporary element (a div) to parse the HTML
    var doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "NA";
  }

  function constructHotelFilterURL() {
    const defaultLocation = "New Delhi";
    const today = new Date();
    const defaultCheckIn = formatDate(today);
    const defaultCheckOut = formatDate(addDays(today, 1));
    const defaultChildren = 0;
    const defaultRoom = 1;
    const defaultAdult = 1;

    const filterLocation = defaultLocation;
    const filterCheckIn = defaultCheckIn;
    const filterCheckOut = defaultCheckOut;
    const filterChildren = defaultChildren;
    const filterRoom = defaultRoom;
    const filterAdult = defaultAdult;

    const url = `?location=${filterLocation}&checkIn=${filterCheckIn}&checkOut=${filterCheckOut}&children=${filterChildren}&room=${filterRoom}&adult=${filterAdult}`;

    return url;
  }

  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  return (
    <div className="w-full py-2 px-3">
      <div className="my-3  w-full xl:w-[1050px] mx-auto  p-3  rounded-lg">
        <div className="flex flex-row justify-between items-center">
          <div className="text-[40px] md:text-[50px] text-[#292929] font-[900] tracking-wide leading-4 uppercase">
            Hotels
          </div>
          <div className="w-[600px] h-[2px] hidden xl:block bg-gray-300 my-auto"></div>
          <div>
            <Button
              style={{
                padding: "8px 24px",
                textAlign: "center",
                border: "none",
                borderRadius: "40px",
                fontWeight: "400",
              }}
              className="btn btn-danger"
              onClick={() => {
                // const url = constructHotelFilterURL();
                navigate(state.link);
              }}
            >
              View All{" "}
              <span>
                <MdOutlineArrowRightAlt className="inline text-[35px] font-[300]" />
              </span>
            </Button>
          </div>
        </div>
        {isLoading ? (
          <div className="w-full text-center p-4">
            <ClipLoader color="#FF0000" size={60} />
          </div>
        ) : (
          <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-items-center">
            {state.hotel.slice(0, 9).map((data, index) => {
              return (
                <div key={index} className="bg-[#fff] rounded-xl shadow-lg">
                  <div className="w-[320px] h-[250px] rounded-xl">
                    <img
                      src={data.propertyPicture[0]?.link || Picture}
                      alt="hotel_images"
                      className="w-full h-full rounded-xl"
                    />
                  </div>
                  <div className="w-[320px] shadow-md  p-3">
                    <h4 className="font-[700] line-clamp-1 w-full  text-[#4E4E4E]">
                      {data.propertyName
                        ? data.propertyName.length > 45
                          ? data.propertyName.slice(0, 45) + "..."
                          : data.propertyName
                        : "Hotel Name"}
                    </h4>
                    <p className="text-[14px] leading-5 text-[#4E4E4E] line-clamp-2">
                      {data.description
                        && removeHtmlTags(data.description)
                          .substring(0, 90) + "..."}
                    </p>
                    <div className="text-right">
                      <Button
                        style={{
                          padding: "5px 15px",
                          textAlign: "center",
                          border: "none",
                          borderRadius: "40px",
                        }}
                        className="btn btn-danger text-sm"
                        onClick={() => navigate(`/hoteldetails/${data._id}/room?${mylink}`)}
                      >
                        Book Now
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default Hotel;
