import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAppContext } from '../../context/store';


function PhotoRouting() {
    const location = useLocation();
    const { state } = useAppContext();
    const hotelId = state.hotelDetails?._id;
    const isActive = (path) => location.pathname === path;

    const searchParams = new URLSearchParams(window.location.search);
    const lat = searchParams.get("latitude");
    const lng = searchParams.get("longitude");
    const locations = searchParams.get("location");
    const checkIn = searchParams.get("checkIn");
    const children = searchParams.get("children");
    const checkOut = searchParams.get("checkOut");
    const room = searchParams.get("room");
    const adult = searchParams.get("adult");
    const holidays = searchParams.get("holidays");

    return (
        <div className='flex flex-row justify-center items-center px-1 pt-4'>
        <Link
            to={`/hoteldetails/${hotelId}/photos?location=${locations}&checkIn=${checkIn}&checkOut=${checkOut}&children=${children}&room=${room}&adult=${adult}&latitude=${lat}&longitude=${lng}&holidays=${holidays}`}
            className={`no-underline ${isActive(`/hoteldetails/${hotelId}/photos`) ? 'border-b-2 border-red-500' : ''}`}
        >
            <div className='mx-1 font-500 text-slate-500 tracking-wider px-3 py-2 my-1 md:my-0 cursor-pointer text-[12px] md:text-[18px]'>
                All
            </div>
        </Link>
        <Link
            to={`/hoteldetails/${hotelId}/photos/roompic?location=${locations}&checkIn=${checkIn}&checkOut=${checkOut}&children=${children}&room=${room}&adult=${adult}&latitude=${lat}&longitude=${lng}&holidays=${holidays}`}
            className={`no-underline ${isActive(`/hoteldetails/${hotelId}/photos/roompic`) ? 'border-b-2 border-red-500' : ''}`}
        >
            <div className='mx-1 font-500 text-slate-500 tracking-wider px-3 py-2 my-1 md:my-0 cursor-pointer  text-[12px] md:text-[18px]'>
                Room
            </div>
        </Link>

        <Link
            to={`/hoteldetails/${hotelId}/photos/property?location=${locations}&checkIn=${checkIn}&checkOut=${checkOut}&children=${children}&room=${room}&adult=${adult}&latitude=${lat}&longitude=${lng}&holidays=${holidays}`}
            className={`no-underline ${isActive(`/hoteldetails/${hotelId}/photos/property`) ? 'border-b-2 border-red-500' : ''}`}
        >
            <div className='mx-1 font-500 text-slate-500 tracking-wider px-2 py-2 my-2 cursor-pointer  text-[12px] md:text-[18px]'>
                Property
            </div>
        </Link>

        <Link
            to={`/hoteldetails/${hotelId}/photos/nearbypic?location=${locations}&checkIn=${checkIn}&checkOut=${checkOut}&children=${children}&room=${room}&adult=${adult}&latitude=${lat}&longitude=${lng}&holidays=${holidays}`}
            className={`no-underline ${isActive(`/hoteldetails/${hotelId}/photos/nearbypic`) ? 'border-b-2 border-red-500' : ''}`}
        >
            <div className='mx-1 font-500 text-slate-500 tracking-wider px-2 py-2 my-2 cursor-pointer text-center whitespace-nowrap  text-[12px] md:text-[18px]'>
                Nearby Attraction
            </div>
        </Link>
    </div>
    )
}

export default PhotoRouting