import React, { useState, useEffect } from "react";
import { BsHouseCheckFill } from "react-icons/bs";
import { useAppContext } from "../../context/store";
import { TiStarFullOutline } from "react-icons/ti";
import { FaLocationPin, FaWifi } from "react-icons/fa6";
import { LuParkingCircle } from "react-icons/lu";
import { Icon } from "@iconify/react";

import { Link } from "react-router-dom";

function HotelCard({ hotel, isWeekend, holidays, weekendHolidays }) {
  const { state } = useAppContext();
  const allRooms = state.allRooms;
  const isMobile = window.innerWidth > 760;
  const containerStyle = {
    textAlign: isMobile ? "right" : "left",
  };

  const desktopMargin = {
    marginBottom: '12px'
  }
  const moboiileMargin = {
    marginBottom: "24px"
  }

  const searchParams = new URLSearchParams(window.location.search);
  const lat = searchParams.get("latitude");
  const lng = searchParams.get("longitude");
  const location = searchParams.get("location");
  const checkIn = searchParams.get("checkIn");
  const children = searchParams.get("children");
  const checkOut = searchParams.get("checkOut");
  const room = searchParams.get("room");
  const adult = searchParams.get("adult");

  const [lowestWeekdayPrice, setLowestWeekdayPrice] = useState(0);
  const [holidayPrice, setHolidayPrice] = useState(0);

  useEffect(() => {
    const hotelRooms = allRooms.filter((room) => room.hotel_id === hotel._id);

    if (hotelRooms.length > 0) {
      let minPrice = 0;
      if (isWeekend) {
        minPrice = hotelRooms.reduce((minObject, currentObject) => {
          return currentObject.weekendPrice < minObject.weekendPrice ? { ...currentObject, holiday: (currentObject.holidayPrice || 0) } : { ...minObject, holiday: (minObject.holidayPrice || 0) };
        }, hotelRooms[0]);
        setLowestWeekdayPrice(minPrice.weekendPrice);
      } else {
        minPrice = hotelRooms.reduce((minObject, currentObject) => {
          return currentObject.weekdayPrice < minObject.weekdayPrice ? { ...currentObject, holiday: (currentObject.holidayPrice || 0) } : { ...minObject, holiday: (minObject.holidayPrice || 0) };
        }, hotelRooms[0]);
        setLowestWeekdayPrice(minPrice.weekdayPrice);
      }

      if ((holidays || 0) + (weekendHolidays || 0) > 0) {
        setHolidayPrice(minPrice.holiday)
      }
    }
  }, [allRooms, hotel._id]);

  function removeHtmlTags(htmlString) {
    // Create a temporary element (a div) to parse the HTML
    var doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "NA";
  }

  const getRatingText = (rating) => {
    if (rating >= 4.5) {
      return "Excellent";
    } else if (rating >= 4.0) {
      return "Very Good";
    } else if (rating >= 3.0) {
      return "Good";
    } else {
      return "Average";
    }
  }

  return (
    <>
      <Link
        to={`/hoteldetails/${hotel?._id}/room?location=${location}&checkIn=${checkIn}&checkOut=${checkOut}&children=${children}&room=${room}&adult=${adult}&latitude=${lat}&longitude=${lng}&holidays=${holidays}&weekendHolidays=${weekendHolidays}`}
        key={hotel?._id}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <div
          key={hotel._id["$oid"]}
          style={isMobile ? desktopMargin : moboiileMargin}
          className="w-[95%] xl:w-[800px] h-auto  bg-[#fff] relative border-[1px] shadow-lg mx-auto flex flex-col md:flex-row justify-start p-3 rounded-lg"
        >
          <div className="w-full md:w-[200px] h-[180px] rounded-lg">
            <img
              src={hotel.propertyPicture[0]?.link || ""}
              alt={hotel.propertyName}
              className="w-full h-full rounded-lg"
            />
          </div>
          <div className="w-full md:w-[350px] ml-3">
            <h3 className="mt-2 md:mt-1 mb-1 font-[700] text-[20px] md:text-[23px]  line-clamp-1">
              {hotel.propertyName}
            </h3>
            <div className="flex flex-row justify-start my-0">
              <div>
                {hotel.rating &&
                  [...Array(Number(hotel.rating))].map((_, i) => (
                    <>
                      <TiStarFullOutline className="inline text-[#FFD250]" />
                    </>
                  ))}
              </div>
              <div className="flex flex-row items-center ml-4">
                <p>
                  <FaLocationPin />
                </p>
                <p className="ml-2">{hotel.city}</p>
              </div>
            </div>

            <p className="leading-6 text-slate-600 text-sm line-clamp-2">
              {removeHtmlTags(hotel?.description).length > 80
                ? removeHtmlTags(hotel?.description).substring(0, 80) + "..."
                : removeHtmlTags(hotel?.description)}
            </p>
            <ul className="w-full  px-0 flex flex-row justify-start items-center">
              {hotel?.facilities.slice(0, 3).map((x) => {
                return (
                  <li className="flex flex-row justify-center items-center mr-3">
                    <span>
                      <Icon
                        icon={x.icon}
                        className=" text-sm text-[#129035] mr-1"
                      />
                    </span>
                    <span className="text-[10px] font-[500]">{x.name}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div
            className={`w-full md:w-[200px] flex flex-row items-start justify-between md:block ${window.innerWidth <= 768
              ? "border-none py-0"
              : "border-l-[1px]"
              } border-slate-400  px-2`}
          >
            <div style={containerStyle}>
              <h4
                style={containerStyle}
                className="font-[900] text-[#f74044]  mb-0"
              >
                {getRatingText(hotel.rating)} {hotel.rating}
              </h4>
              <p style={containerStyle} className="text-[#f74044]">
                ({hotel.rating} Rating)
              </p>
            </div>
            <div style={containerStyle}>
              <h3 className="text-[20px] md:text-[22px] text-slate-600  font-[500] text-right">
                {state.currency} {((parseFloat(lowestWeekdayPrice) + ((parseFloat(lowestWeekdayPrice) * parseInt(holidayPrice)) / 100)) * state.rate).toFixed(2)}
              </h3>
              <div style={containerStyle}>
                <p className="mb-0 text-[16px] md:text-[18px] text-right">
                  + {hotel.taxesAndFees} Taxes & Fee
                </p>
                <p className="mb-0 text-[16px] text-right">Per Night</p>
              </div>
              {/* Conditional Rendering for Promoted Badge */}
              {hotel?.promoted?.status && (
                <div className="bg-[#292929] text-[#fff]  absolute p-[6px] right-[280px] md:right-5 text-[9px] rounded-xl font-[500] bottom-2">Promoted</div>
              )}
            </div>
          </div>

        </div>
      </Link>
    </>
  );
}

export default HotelCard;



