import React, { useState, useEffect } from "react";
import FilterHeader from "./FilterHeader";
import FilterCard from "./FilterCard";
import FilterMap from "./FilterMap";
import { useAppContext } from "../../context/store";
import { FaStar } from "react-icons/fa6";
import axios from "axios";
import config from "../../config";
import { FaArrowsAltH } from "react-icons/fa";

function FilterSection({ filteredHotels, setFilteredHotels, longitude, latitude, hasWeekend, isWeekend, holidays, weekendHolidays }) {
  const [minBudget, setMinBudget] = useState("");
  const [maxBudget, setMaxBudget] = useState("");
  const [starColors, setStarColors] = useState({
    5: false,
    4: false,
    3: false,
    2: false,
    1: false,
  });
  const [facilities, setFacilities] = useState([])
  const [ammenities, setAmmenities] = useState([])
  const [budgetFilter, setBudgetFilter] = useState({
    min: "",
    max: ""
  })

  useEffect(() => {
    const getFacilities = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/dashboard/facilities`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
           mysecret: config.MY_SECRET,
          },
        }
      );

      setFacilities(response.data.data);
    }

    const getAmmenities = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/dashboard/ammenities`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
           mysecret: config.MY_SECRET,
          },
        }
      );

      setAmmenities(response.data.data);
    }

    getAmmenities();
    getFacilities();
  }, [])

  const { state, actions } = useAppContext();
  const allHotels = state.hotel;
  const [filters, setFilters] = useState({
    propertyType: [],
    facilities: [],
    ammenities: [],
    starRating: [],
    priceRange: []
  });

  const applyFilters = () => {
    let updatedHotels = [...state.hotel];

    if (filters.starRating.length > 0) {
      updatedHotels = updatedHotels.filter((hotel) =>
        filters.starRating.includes(hotel.rating.toString())
      );
    }

    if (filters.propertyType.length > 0) {
      //console.log('bbbbbbb');
      updatedHotels = updatedHotels.filter((hotel) =>
        filters.propertyType.includes(hotel.propertyType)
      );
    }

    if (filters.facilities.length > 0) {
      updatedHotels = updatedHotels.filter((hotel) =>
        hotel.facilities &&
        hotel.facilities.some((f) =>
          filters.facilities.includes(f.name)
        )
      );
    }

    if (filters.ammenities.length > 0) {
      updatedHotels = updatedHotels.filter((hotel) =>
        hotel.ammenities &&
        hotel.ammenities.some((f) =>
          filters.ammenities.includes(f.name)
        )
      );
    }

    if (budgetFilter.min !== "" && budgetFilter.max !== "") {
      if (isWeekend) {
        updatedHotels = updatedHotels.filter((hotel) =>
          hotel.rooms &&
          hotel.rooms[0] &&
          hotel.rooms[0].weekendPrice &&
          parseInt(hotel.rooms[0].weekendPrice) >= budgetFilter.min &&
          parseInt(hotel.rooms[0].weekendPrice) <= budgetFilter.max
        );
      } else {
        updatedHotels = updatedHotels.filter((hotel) =>
          hotel.rooms &&
          hotel.rooms[0] &&
          hotel.rooms[0].weekdayPrice &&
          parseInt(hotel.rooms[0].weekdayPrice) >= budgetFilter.min &&
          parseInt(hotel.rooms[0].weekdayPrice) <= budgetFilter.max
        );
      }
    }

    setFilteredHotels(updatedHotels);
  };

  useEffect(() => {
    //console.log(filters.priceRange, 'rrrrrrr');
    let setMin = false;
    filters.priceRange.forEach(priceRange => {
      if (priceRange.includes('0 - 1500')) {
        setMin = true;
        setMinBudget(0);
        setMaxBudget(1500);
      } else if (priceRange.includes('1500 - 2500')) {
        if (setMin === false) {
          setMin = true;
          setMinBudget(1500);
        }
        setMaxBudget(2500);
      } else if (priceRange.includes('2500 - 3500')) {
        if (setMin === false) {
          setMin = true;
          setMinBudget(2500);
        }
        setMaxBudget(3500);
      } else if (priceRange.includes('3500 - 4500')) {
        if (setMin === false) {
          setMin = true;
          setMinBudget(3500);
        }
        setMaxBudget(4500);
      } else if (priceRange.includes('4500+')) {
        if (setMin === false) {
          setMin = true;
          setMinBudget(4500);
        }
        setMaxBudget(Infinity);
      }
    });
  }, [filters.priceRange])

  const handleCheckboxChange = (e, filterType, value) => {
    if (e.target.checked) {
      setFilters((prevFilters) => {
        const currentFilterType = prevFilters[filterType] || [];

        setMinBudget("");
        setMaxBudget("");

        return {
          ...prevFilters,
          [filterType]: currentFilterType.includes(value)
            ? currentFilterType.filter((v) => v !== value)
            : [...currentFilterType, value],
        };
      });
    } else {
      setFilters((prevFilters) => {
        const currentFilterType = prevFilters[filterType] || [];
        setMinBudget('');
        setMaxBudget('');
        return {
          ...prevFilters,
          [filterType]: currentFilterType.filter((v) => v !== value)
        };
      });
    }
  };

  useEffect(() => {
    applyFilters();
  }, [filters, budgetFilter, state.hotel]);

  const handleMinBudgetChange = (e) => {
    setMinBudget(e.target.value);
  };

  const handleMaxBudgetChange = (e) => {
    setMaxBudget(e.target.value);
  };

  const handleBudgetFilter = () => {
    setBudgetFilter({
      max: maxBudget,
      min: minBudget
    })
  };

  return (
    <div>
      <FilterHeader />
      <div className="w-full xl:w-[1100px] mx-auto flex flex-row justify-between">
        <div className="hidden xl:block">
          <div>
            <FilterMap latitude={latitude} longitude={longitude} />
          </div>
          <div className="w-[250px] h-[900px] bg-[#fff] shadow-md py-2 rounded-lg mt-4 overflow-y-auto scroller cursor-all-scroll">
            {/* <div>
              <h3 className="text-[20px] text-slate-700 font-[700] px-3 py-2 mb-1">
                Price Per Night
              </h3>
              <div className="px-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="Last Minute Deal"
                    id="flexCheckDefault"
                    onChange={(e) =>
                      handleCheckboxChange(e, "priceRange", "NPR 0 - 1500")
                    }
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    NPR 0 - 1500
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="5 Star"
                    id="flexCheckDefault"
                    onChange={(e) =>
                      handleCheckboxChange(e, "priceRange", "NPR 1500 - 2500")
                    }
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    NPR 1500 - 2500
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=" North Nepal"
                    id="flexCheckDefault"
                    onChange={(e) =>
                      handleCheckboxChange(e, "priceRange", "NPR 2500 - 3500")
                    }
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    NPR 2500 - 3500
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=" North Nepal"
                    id="flexCheckDefault"
                    onChange={(e) =>
                      handleCheckboxChange(e, "priceRange", "NPR 3500 - 4500")
                    }
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    NPR 3500 - 4500
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=" North Nepal"
                    id="flexCheckDefault"
                    onChange={(e) =>
                      handleCheckboxChange(e, "priceRange", "NPR 4500+")
                    }
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    NPR 4500+
                  </label>
                </div>
              </div>
            </div> */}
            <div>
              <h3 className="text-[20px] text-slate-700 font-[700] px-3 py-2 mb-1">
                Your Budget
              </h3>
              <div className="flex flex-row justify-center items-center p-2">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Min"
                    aria-label="Min Budget"
                    aria-describedby="basic-addon1"
                    value={minBudget}
                    onChange={handleMinBudgetChange}
                  />
                </div>
                <span className="text-[20px] font-[600] px-1">
                  <FaArrowsAltH className="inline" />
                </span>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Max"
                    aria-label="Max Budget"
                    aria-describedby="basic-addon1"
                    value={maxBudget}
                    onChange={handleMaxBudgetChange}
                  />
                </div>
                <button
                  onClick={handleBudgetFilter}
                  className="bg-slate-500 p-2 rounded-md ml-2 text-sm text-slate-100"
                >
                  Apply
                </button>
              </div>
            </div>
            <div>
              {/* Star rating */}
              <h3 className="text-[20px] text-slate-700 font-[700] px-3 py-2 mb-1">
                Star Rating
              </h3>
              <div className="px-3">
                {[5, 4, 3, 2, 1].map((rating) => (
                  <div key={rating} className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={`${rating} Star`}
                      id={`flexCheckDefault${rating}`}
                      onChange={(e) =>
                        handleCheckboxChange(e, "starRating", `${rating}`)
                      }
                    />
                    <label
                      className="form-check-label flex flex-row items-center"
                      htmlFor={`flexCheckDefault${rating}`}
                    >
                      <span>
                        <FaStar
                          className={`${starColors[rating]
                            ? "text-[#FDCC0D]"
                            : "text-[#ccc]"
                            } font-[700]`}
                        />
                      </span>
                      <span className="ml-2 font-[400] text-slate-800">
                        {rating} Star
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h3 className="text-[20px] text-slate-700 font-[700] px-3 py-2 mb-1">
                Property Type
              </h3>
              <div className="px-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="Hotel"
                    id="flexCheckDefaultHotel"
                    onChange={(e) =>
                      handleCheckboxChange(e, "propertyType", "Hotel")
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefaultHotel"
                  >
                    Hotel
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="5 Star"
                    id="flexCheckDefaulxt"
                    onChange={(e) =>
                      handleCheckboxChange(e, "propertyType", "Apartment")
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefaulxt"
                  >
                    Apartment
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=" North Nepal"
                    id="flexCheckDefaultz"
                    onChange={(e) =>
                      handleCheckboxChange(e, "propertyType", "Villa")
                    }
                  />
                  <label className="form-check-label" for="flexCheckDefaultz">
                    Villa
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=" North Nepal"
                    id="flexCheckDefaultzz"
                    onChange={(e) =>
                      handleCheckboxChange(e, "propertyType", "Homestay")
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefaultzz"
                  >
                    Homestay
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=" North Nepal"
                    id="flexCheckDefaultzzq"
                    onChange={(e) =>
                      handleCheckboxChange(e, "propertyType", "Resort")
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefaultzzq"
                  >
                    Resort
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=" North Nepal"
                    id="flexCheckDefaultzza"
                    onChange={(e) =>
                      handleCheckboxChange(e, "propertyType", "Guest house")
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefaulzza"
                  >
                    Guest house
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=" North Nepal"
                    id="flexCheckDefaultxxx"
                    onChange={(e) =>
                      handleCheckboxChange(e, "propertyType", "Hostel")
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefaultxxx"
                  >
                    Hostel
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=" North Nepal"
                    id="flexCheckDefaultqq"
                    onChange={(e) =>
                      handleCheckboxChange(e, "propertyType", "Camp")
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefaultqq"
                  >
                    Camp
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=" North Nepal"
                    id="flexCheckDefaultww"
                    onChange={(e) =>
                      handleCheckboxChange(e, "propertyType", "Tree house")
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefaultww"
                  >
                    Tree house
                  </label>
                </div>
              </div>
            </div>
            {/* Guest rating */}
            {/* <div>
              <h3 className="text-[20px] text-slate-700 font-[700] px-3 py-2 mb-1">
                Guest Rating
              </h3>
              <div className="px-3">
                {["Excellent", "Very Good", "Good", "No Rating"].map(
                  (data, rating) => (
                    <div key={rating} className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={data}
                        id={`flexCheckDefault${data}`}
                        onChange={() =>
                          handleCheckboxChange("starRating", data)
                        }
                        checked={gueststarColors[data]}
                      />
                      <label
                        className="form-check-label flex flex-row items-center"
                        htmlFor={`flexCheckDefault${data}`}
                      >
                        <span>
                          <FaStar
                            className={`text-${
                              gueststarColors[data] ? "[#FDCC0D]" : "[#ccc]"
                            } font-[700]`}
                          />
                        </span>
                        <span className="ml-2 font-[400] text-slate-800">
                          {data}
                        </span>
                      </label>
                    </div>
                  )
                )}
              </div>

       
            </div> */}
            {/* Facilities */}
            <div>
              <h3 className="text-[20px] text-slate-700 font-[700] px-3 py-2 mb-1">
                Facilities
              </h3>
              <div className="px-3">
                {facilities.length > 0 && facilities.map(x => {
                  return (
                    <div className="form-check mb-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={x.name}
                        id="flexCheckDefaultee"
                        onChange={(e) =>
                          handleCheckboxChange(e, "facilities", x.name)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefaultee"
                      >
                        {x.name}
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
            <div>
              <h3 className="text-[20px] text-slate-700 font-[700] px-3 py-2 mb-1">
                Ammenities
              </h3>
              <div className="px-3">
                {ammenities.length > 0 && ammenities.map(x => {
                  return (
                    <div className="form-check mb-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={x.name}
                        id="flexCheckDefaultee"
                        onChange={(e) =>
                          handleCheckboxChange(e, "ammenities", x.name)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefaultee"
                      >
                        {x.name}
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full xl:w-[850px] px-2">
          <FilterCard weekendHolidays={weekendHolidays} holidays={holidays} isWeekend={isWeekend} hasWeekend={hasWeekend} filteredHotels={filteredHotels} />
        </div>
      </div>
    </div>
  );
}

export default FilterSection;
